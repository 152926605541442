import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Editor } from "@tinymce/tinymce-react";
import { Toaster, toast } from "sonner";
import { Link, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import axios from "axios";
import PrivateImage from "../../PrivateImage/PrivateImage";
import { useFormik } from "formik";
import moment from "moment";
import { Fragment, useState, useCallback, useEffect } from "react";

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};



const AllSubmission = ({ passData }) => {
  var storedUser = localStorage.getItem('Single_user_submission');
  var parsedUser = JSON.parse(storedUser);
  console.log('User:', parsedUser);
    
  const [previousEditorValue, setPreviousEditorValue] = useState(null);
  const [updateEditorValue, setUpdateEditorValue] = useState("");
  const [allTask, setAllTask] = useState([]);
  const [allSubmissionDetails, setAllSubmissionDetails] = useState(null);
  const [updatedStatus, setupdatedStatus] =
    useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [isNotFound, setIsNotFound] = useState(0);
  const { eventID } = useParams();
  useEffect(() => {
    const fetchAllSubmission = async () => {
      try {
        const result = await axios
          .get(
            `${window.$domain}/api/resource/Rep Task Submissions/${eventID}`,
            config
          )
          .then((response) => {
            setAllSubmissionDetails(response.data.data);
            console.log("Single Submission", response.data);
            setPreviousEditorValue(response?.data?.data?.submission_comments)
            setLoadingSkeleton(false);
            setIsNotFound(false);
            if (response.data.data == 0) {
              setIsNotFound(true);
              setLoadingSkeleton(true);
              toast.error("No Data Found");
            }
          });
      } catch (e) {
        console.log(e);

        if (e.code == "ERR_NETWORK" || e.response.status == 404) {
          setIsNotFound(true);

          toast.error("No Data Found");
          setLoadingSkeleton(true);
        }
      }
    };
    fetchAllSubmission();
  }, []);
  const handleEditorChange = (content, editor) => {
    setUpdateEditorValue(content);
    console.log("Content was updated:", content);
  };
  const UpdateSubmissionStatus = useFormik({
    initialValues: {

      submission_status: allSubmissionDetails?.submission_status,

    },
    onSubmit: (values) => {
      var jsontoPass = {
        submission_status: values.submission_status,
        submission_comments: updateEditorValue,

      };

      const fetchAmbData = async (jsontoPass) => {
        // console.log(jsontoPass);
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Rep Task Submissions/${allSubmissionDetails?.name}`,
              jsontoPass,
              config
            )
            .then((res) => {
              console.log("asfawfaewf", res);
              toast.success("Updated successfully");
              setupdatedStatus(!updatedStatus);
            });
        } catch (e) {
          console.log(">>>>>>>>>", e);
          toast.error("Announcement failed");
        }
      };
      fetchAmbData(jsontoPass);
    },

    enableReinitialize: true,
  });
  const goBack = () => {
    window.history.back();
  };
  return (<>
    <div className="mt-4">
      <div className="flex  divide-y flex-col justify-end">
        <div className="mt-4 sm:ml-6 sm:mt-0  flex justify-start items-center   sm:flex-none">

          <span onClick={() => goBack()} className="hover:cursor-pointer flex justify-start items-center"><ArrowLeftIcon className="h-5 w-5 mr-1 flex justify-center items-center  text-gray-500 cursor-pointer" /> Back</span>


        </div>

      </div>
      <div className="mt-3 flow-root">
       <div className="-mx-4 -my-2 max-h-screen overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="inline-block min-w-full border rounded-md border-gray-300 py-2 align-middle sm:px-3 lg:px-2">
              <table className="min-w-full ">
                <thead className='sr-only ' >
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>

                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>

                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">

                  <tr >
                    <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-11 w-11 cursor-pointer flex-shrink-0" onClick={() => goBack()}>
                          <PrivateImage
                            imageUrl={parsedUser?.submitted_by?.user_image}
                            className={"h-11 w-11 rounded-full"}
                          />

                        </div>
                        <div className="ml-4">
                          <div className="font-medium cursor-pointer text-gray-900" onClick={() => goBack()}>{parsedUser?.submitted_by?.first_name + " " + parsedUser?.submitted_by?.last_name}</div>
                          <div className="mt-1 text-gray-500">{parsedUser?.submitted_by?.name}</div>
                        </div>
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                    {parsedUser?.submission_status == "Rejected" ? (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              {parsedUser?.submission_status}
                            </span>
                          ) : parsedUser?.submission_status == "Closed" ? (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                              {parsedUser?.submission_status}
                            </span>
                          ) :parsedUser?.submission_status == "In Review" ? (
                            <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-700">
                              {parsedUser?.submission_status}
                            </span>
                          ) :(
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {parsedUser?.submission_status}
                            </span>
                          )}
                      </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" >  {moment(parsedUser?.creation).format('LL')}</span>

                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 className="text-sm font-medium mt-4 px-4 text-gray-500">Photos</h2>
      {allSubmissionDetails?.image_attachments.length == 0 || allSubmissionDetails?.image_attachments == null || allSubmissionDetails?.image_attachments == undefined ? <div className="mt-3 py-5 mx-2 bg-gray-200  border-dotted border-2 border-gray-900 rounded-md px-4 flex justify-center "><div className="flex items-center border-dotted border-gray-900 justify-center  rounded-md ">

        No Attachments
      </div></div> : <div className="mt-3 py-5 mx-2  rounded-md px-4 flex flex-wrap  gap-3">
        {allSubmissionDetails?.image_attachments?.map((project) => (
          <div key={project.name} className=" flex rounded-md shadow-sm">

            <div className="flex  items-center justify-between border truncate rounded-md  border-gray-400 bg-blue-300">

              <a href={"https://dash.bestdiplomats.org" + project.attachment} target="_blank" className="font-medium text-gray-900 hover:text-gray-600">

                <PrivateImage
                  imageUrl={project?.attachment}
                  className={" h-24 w-24 object-cover"}
                />
              </a>

            </div>
          </div>
        ))} </div>}

    </div>
    <div>
      <h2 className="text-sm font-medium mt-4 px-4 text-gray-500">Documents</h2>
      {allSubmissionDetails?.document_attachments.length == 0 || allSubmissionDetails?.document_attachments == null || allSubmissionDetails?.document_attachments == undefined ? <div className="mt-3 py-5 mx-2 bg-gray-200  border-dotted border-2 border-gray-900 rounded-md px-4 flex justify-center "><div className="flex items-center border-dotted border-gray-900 justify-center  rounded-md ">

        No Attachments

      </div></div> : <div className="mt-3 py-5 mx-2 bg-gray-200 rounded-md px-4 flex  gap-5 ">
        {allSubmissionDetails?.document_attachments?.map((project) => (


          <div key={project.name} className="flex  items-center flex-wrap  justify-between  rounded-md border-gray-200 bg-white">
            <div className="] text-sm">

              <a
                href={
                  "https://dash.bestdiplomats.org" +
                  project.attachment
                }
                target="_blank"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                {project.attachment_name}
                <svg
                  className="w-5 h-5 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  ></path>
                </svg>
              </a>
            </div>


          </div>
        ))}</div>}


    </div>
    <div>
      <h2 className="text-sm font-medium mt-4 px-4 text-gray-500">Videos</h2>

      {allSubmissionDetails?.video_attachments.length == 0 || allSubmissionDetails?.video_attachments == null || allSubmissionDetails?.video_attachments == undefined ? <div className="mt-3 py-5 mx-2 bg-gray-200  border-dotted border-2 border-gray-900 rounded-md px-4 flex justify-center "><div className="flex items-center border-dotted border-gray-900 justify-center  rounded-md ">

        No Attachments

      </div></div> : <div className="mt-3 py-5 mx-2 bg-gray-200 rounded-md px-4 flex  gap-5 ">{allSubmissionDetails?.video_attachments?.map((project) => (


        <div key={project.name} className="flex  items-center justify-between flex-wrap   rounded-md border-gray-200 bg-white">
          <div className="] text-sm">

            <a
              href={
                "https://dash.bestdiplomats.org" +
                project.attachment
              }
              target="_blank"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {project.attachment_name}
              <svg
                className="w-5 h-5 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                ></path>
              </svg>
            </a>
          </div>


        </div>
      ))} </div>}


    </div>
    <div>
      <h2 className="text-sm font-medium mt-4 px-4 text-gray-500">Google Drive Link</h2>

      {allSubmissionDetails?.attachment_links.length == 0 || allSubmissionDetails?.attachment_links == null || allSubmissionDetails?.attachment_links == undefined ? <div className="mt-3 py-5 mx-2 bg-gray-200  border-dotted border-2 border-gray-900 rounded-md px-4 flex justify-center "><div className="flex items-center border-dotted border-gray-900 justify-center  rounded-md ">
        No Attachments
      </div></div> : <div className="mt-3 py-5 mx-2 bg-gray-200 rounded-md  px-4 flex  gap-5 ">{allSubmissionDetails?.attachment_links?.map((project) => (<div key={project?.name} className="flex  items-center justify-between flex-wrap  rounded-md border-gray-200 bg-white">
        <div className=" text-sm">

          <a
            href={
              "https://dash.bestdiplomats.org" +
              project?.submission_link
            }
            target="_blank"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {project.submission_name} 
            <svg
              className="w-5 h-5 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              ></path>
            </svg>
          </a>
        </div>


      </div>
      ))}</div>}

    </div>

    <div>

      <form onSubmit={UpdateSubmissionStatus.handleSubmit} className='mt-6 py-5 px-2 '><div className="">
        <label htmlFor="submission_status" className="block  text-sm font-medium leading-6 text-gray-900">
          Submission Status
        </label>
        <div className="mt-1">
          <select
            id="submission_status"
            name="submission_status"
            onChange={UpdateSubmissionStatus.handleChange}
            value={UpdateSubmissionStatus.values.submission_status}
            autoComplete="submission_status-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="Submitted">Submitted</option>
            <option value="Approved">Approved</option>
            <option value="In Review">In Review</option>
            <option value="Rejected">Rejected</option>
            <option value="Closed">Closed</option>
          </select>
        </div>

        <div className="mt-3">

          <h2 className=" text-sm font-medium leading-6 text-gray-900">Submission Comments</h2>
          <div className="mt-2">
            <Editor
              initialValue={previousEditorValue}
              apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
              init={{
                height: "400",
                borderColor: "20px",
                branding: false,
                menubar: true,
                plugins:
                  "print preview paste searchreplace autolink directionality visualblocks visualchars link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                toolbar:
                  "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                image_advtab: true,
              }}
              onEditorChange={handleEditorChange}
            />
          </div>
          <div>

          </div>

        </div>
      </div>
        <div className="flex justify-end mt-3"><button type='submit' className="text-white  bg-blue-700 hover:bg-blue-800  focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-4 text-center  dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center">
          Update
        </button></div>  </form>
    </div>
  </>
  )
}

export default AllSubmission