import React, { useEffect, useState } from 'react'

import BlankCertificate from '../../img/Certificate of Award impact Director.png'
import FontAbhaya from '../../AbhayaLibre-SemiBold.ttf'
import RobotoBold from '../../Roboto-Bold.ttf'
import AbhayaRegular from '../../AbhayaLibre-Regular.ttf'
import AbhayaBold from '../../AbhayaLibre-Bold.ttf'
import GoodVibrations from '../../GoodVibrations Script.ttf'
import PoppinsSemiBold from '../../Poppins-SemiBold.ttf'
import PoppinsRegular from '../../Poppins-Regular.ttf'
import QRCode from 'qrcode'
import { Document, Page, Text, View, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import moment from "moment";

const AwardCertificateForImpactDirector = (props) => {

    if (
        (
          props.FirstName +
          props.LastName
        ).length > 25
      ) {
        var setFontSize = 36;
                  var setFullNameTop = 389.7;
                  var setLowerViewTop =390;
                  var setcomplete =437.4;
      } else {
        
        var setFontSize = 44;
        var setFullNameTop = 378.7;
        var setLowerViewTop = 387.2;
         var setcomplete = 427.5;
      }

    Font.register({
        family: "AbhayaLibreSemiBold",
        src: FontAbhaya,
      });
      Font.register({
        family: "RobotoBold",
        src: RobotoBold,
      });
      Font.register({
        family: "AbhayaRegular",
        src: AbhayaRegular,
      });
      Font.register({
        family: "AbhayaBold",
        src: AbhayaBold,
      });
      Font.register({
        family: "PoppinsSemiBold",
        src: PoppinsSemiBold,
      }); Font.register({
        family: "GoodVibrations",
        src: GoodVibrations,
      }); Font.register({
        family: "PoppinsRegular",
        src: PoppinsRegular,
      });
      const styles = StyleSheet.create({
        page: {},
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        viewer: {
          width: 870,
          height: 690,
        },
        bgimages: {
          height:595,
          width:842,
          position: 'absolute'
        },
        title: {
          top: 15,
          textAlign: "center",
          fontSize: 58,
          color: "white",
          fontFamily: "AbhayaLibreSemiBold",
        },
        participation: {
          top: 15,
          textAlign: "center",
          fontSize: 21,
          color: "#F3D649",
          fontFamily: "RobotoBold",
        },
        initialline: {
          letterSpacing: 1.5,
          top: 210,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        showcase: {
        //   top: 250,
        marginTop:4,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "PoppinsRegular",
          marginHorizontal: 140,
        },
        complete: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        completeDate: {
            top:420.8,
            left:96.5,
            textAlign: "start",
            fontSize: 13,
            color: "#002060",
            fontFamily: "PoppinsRegular",
        },
        completeLocation: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        fullname: {
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        headNameRight: {
          top:288,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
        },
        headNameLeft: {
          top:288,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
          marginLeft: 18,
        },
        headPositionRight: {
          top:286,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginRight: 45,
        },
        headPositionLeft: {
          top:286,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginLeft: 55,
        },
        QRImage: {
          top:504,
          left: 390,
          position: "absolute",
          width: 75,
        },
      });
    
    // States
   
    const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#4c8dcb", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  generateQR(props.qrText);
    
  return (
  
    <Document>
    <Page size={[842, 595]}>
      <Image
        src={BlankCertificate}
        style={styles.bgimages}
      ></Image>
     
      <Text
        style={{
          top: setFullNameTop,
          fontSize: setFontSize,
          textAlign: "center",
          color: "#002060",
          textTransform: "capitalize",
          fontFamily: "GoodVibrations",
        }}
      >
        {""}
        {"" +
          props.FirstName?.toLowerCase() +
          " " +
          props.LastName?.toLowerCase()}
      </Text>
      
      <View style={{ top: setLowerViewTop }}>
                     
                        <Text style={styles.showcase}>
                        In recognition of your outstanding contribution as a Guest Speaker at {props.event?.title}, We hereby acknowledge and express our heartfelt gratitude for your invaluable insights, expertise, and dedication to enriching our event.
                        </Text>

                       
    
      </View>
      <Text style={{   top:393.8,
                            left:96.5,
                            textAlign: "start",
                            fontSize: 13,
                            color: "#000000",
                            fontFamily: "PoppinsRegular",}}>
            {moment(props?.end_date).format(
              "D MMMM, YYYY"
            ) }
          </Text>
      {/* <Image
        source={generateQR(
          `https://verify.bestdiplomats.org/verify/${response.data.data[index]?.delegate_profile.name}-${eventId}-${response.data.data[index]?.name}`
        )}
        style={styles.QRImage}
      ></Image> */}
    </Page>
  </Document>
  )
}



export default AwardCertificateForImpactDirector