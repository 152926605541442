import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { toast, Toaster } from "sonner";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  PhotoIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { Formik, useFormik } from "formik";

import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import moment from "moment/moment";
import PrivateImage from "../PrivateImage/PrivateImage";
import { Editor } from "@tinymce/tinymce-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    department: "Optimization",
    email: "lindsay.walton@example.com",
    role: "Member",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More people...
];
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};
const team = [
  {
    name: "Tom Cook",
    email: "tom.cook@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Whitney Francis",
    email: "whitney.francis@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leonard Krasner",
    email: "leonard.krasner@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Floyd Miles",
    email: "floyd.miles@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Emily Selman",
    email: "emily.selman@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

const CreateEventa = () => {
  const [open, setOpen] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [submitDone, setSubmitDone] = useState(false);
  const [allEvents, setAllEvents] = useState(null);
  const [eventsDetails, setEventsDetails] = useState();
  const [createEventsImage, setCreateEventsImage] = useState(false);
  const [totalEvents, setTotalEvents] = useState(null);
  const [submitImage, setSumbitImage] = useState(false);
  const [flightDocument, setFlightDocument] = useState(false);
  const [loaderFlightFile, setLoaderFlightFile] = useState(false);
  const [rulesAndProcedures, setRulesAndProcedures] = useState(null);
  const [rulesAndProceduresPrevious, setRulesAndProceduresPrevious] = useState(null);
  const [rulesAndProceduresloder, setRulesAndProceduresloder] = useState(false);
  const [studyGuideImage, setStudyGuideImage] = useState(null);
  const [studyGuidePreviousImage, setStudyGuidePreviousImage] = useState(null);
  const [rulesStudyGuideloder, setStudyGuideloder] = useState(false);
  const [fullExperienceItineraryImage, setFullExperienceItineraryImage] = useState(null);
  const [fullExperiencePreviousImage, setFullExperiencePreviousImage] = useState(null);
  const [fullExperienceItineraryloder, setFullExperienceItineraryloder] = useState(false);
 const [regularEventItineraryImage, setRegularEventItineraryImage] = useState(null);
  const [regularEventItineraryPreviousImage, setRegularEventItineraryPreviousImage] = useState(null);
  const [regularEventTopicAttachmenImage, setRegularEventTopicAttachmenImage] = useState(null);
  const [regularEventItineraryloder, setRegularEventItineraryloder] = useState(false);
  const [eventTopicAttachmentloader , setEventTopicAttachmentloader] = useState(false);
  const [eventTopicAttachmenImage, setEventTopicAttachmenImage] = useState(null);
  const [uploadFileResponse, setUploadFileResponse] = useState(null);
  const [previousEditorValue, setPreviousEditorValue] = useState(null);
  const [updateEditorValue, setUpdateEditorValue] = useState("");
  const [editorValue, setEditorValue] = useState("");
  useEffect(() => {
    const geteventdata = async () => {
      const result = await axios
        .get(`https://dash.bestdiplomats.org//api/resource/Events?fields=["*"]`)
        .then((res) => {
          setAllEvents(res.data.data);
          console.log(res.data.data);
          setTotalEvents(res.data.data.length)
          setLoadingSkeleton(false)
          // console.log(">>>>> tic", res.data.data);
        })
        .catch((e) => {
          console.log("errorro", e);
        });
    };
    geteventdata();
  }, [submitDone]);

  const handleViewDetails = (e) => {
    setEventsDetails(e);
    setPreviousEditorValue(e?.event_topic_description)
    setRegularEventTopicAttachmenImage(e?.event_topic_attachment)
    setRegularEventItineraryPreviousImage(e.regular_event_itinerary)
    setFullExperiencePreviousImage(e.full_experience_itinerary)
    setStudyGuidePreviousImage(e.study_guide)
    setRulesAndProceduresPrevious(e.rules_and_procedures)
    setCreateEventsImage(false);
    console.log("><><", e);
    setOpen(true);
  };
  function uploadFlightDocument(e) {
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const uploadFlightApi = async () => {
      try {
        setLoaderFlightFile(true);
        setSumbitImage(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            // console.log(responseFlight);

            await setUploadFileResponse(responseFlight.data);
            setCreateEventsImage(true);
            setSumbitImage(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
        setLoaderFlightFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }
  function RulesAndProceduresImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const RulesAndProcedures = async () => {
      try {
        setRulesAndProceduresloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><RulesAndProceduresImage><",responseFlight);

            await setRulesAndProcedures(responseFlight.data);
          
            setRulesAndProceduresloder(false);
            
          });
          setRulesAndProceduresloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    RulesAndProcedures();
  }
  function StudyGuideImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const studyGuide = async () => {
      try {
        setStudyGuideloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><setStudyGuideImage><",responseFlight);

            await setStudyGuideImage(responseFlight.data);
          
            setStudyGuideloder(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
          setStudyGuideloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    studyGuide();
  }
  function FullExperienceItineraryImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const fullExperienceItinerary = async () => {
      try {
        setFullExperienceItineraryloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><setStudyGuideImage><",responseFlight);

            await setFullExperienceItineraryImage(responseFlight.data);
          
            setFullExperienceItineraryloder(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
          setFullExperienceItineraryloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    fullExperienceItinerary();
  } 
  function regularItineraryImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const regularItinerary = async () => {
      try {
        setRegularEventItineraryloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><setRegularEventItineraryloder><",responseFlight);

            await setRegularEventItineraryImage(responseFlight.data);
          
            setRegularEventItineraryloder(false);
          
          });
          setRegularEventItineraryloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    regularItinerary();
  } function RulesAndProceduresImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const RulesAndProcedures = async () => {
      try {
        setRulesAndProceduresloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><RulesAndProceduresImage><",responseFlight);

            await setRulesAndProcedures(responseFlight.data);
          
            setRulesAndProceduresloder(false);
            
          });
          setRulesAndProceduresloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    RulesAndProcedures();
  }
  function StudyGuideImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const studyGuide = async () => {
      try {
        setStudyGuideloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><setStudyGuideImage><",responseFlight);

            await setStudyGuideImage(responseFlight.data);
          
            setStudyGuideloder(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
          setStudyGuideloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    studyGuide();
  }
  function FullExperienceItineraryImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const fullExperienceItinerary = async () => {
      try {
        setFullExperienceItineraryloder(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><setStudyGuideImage><",responseFlight);

            await setFullExperienceItineraryImage(responseFlight.data);
          
            setFullExperienceItineraryloder(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
          setFullExperienceItineraryloder(false);
      } catch (e) {
        console.log(e);
      }
    };

    fullExperienceItinerary();
  } 

  function EventTopicAttachmentImage(e) {
    console.log(e);
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const EventsAttachmentData = async () => {
      try {
        setEventTopicAttachmentloader(true);
        
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            console.log("><setEventTopicAttachmenImage><",responseFlight);

            await setEventTopicAttachmenImage(responseFlight.data);
          
            setEventTopicAttachmentloader(false);
          
          });
          setEventTopicAttachmentloader(false);
      } catch (e) {
        console.log(e);
      }
    };

    EventsAttachmentData();
  }

  const UpdateEvents = useFormik({
    initialValues: {
      booking_reference_number: eventsDetails?.booking_reference_number,
      title: eventsDetails?.title,
      description: eventsDetails?.description,
      start_date: eventsDetails?.start_date,
      end_date: eventsDetails?.end_date,
      full_experience_end_date: eventsDetails?.full_experience_end_date,
      event_topic_title: eventsDetails?.event_topic_title,
      city: eventsDetails?.city,
      country: eventsDetails?.country,
      status: eventsDetails?.status,
      venue: eventsDetails?.venue,
      venue_details: eventsDetails?.venue_details,
      main_picture: eventsDetails?.main_picture,
    },
    onSubmit: (values) => {
      var PassJson = {
        booking_reference_number: values.booking_reference_number,
        title: values.title,
        description: values.description,
        start_date: values.start_date,
        end_date: values.end_date,
        full_experience_end_date: values.full_experience_end_date,
        full_experience_itinerary: values.full_experience_itinerary,
        event_topic_description:updateEditorValue,  
        event_topic_title: values?.event_topic_title,
        city: values.city,
        country: values.country,
        status: values.status,
        venue: values.venue,
        venue_details: values.venue_details,
        main_picture: uploadFileResponse?.message.file_url,
        rules_and_procedures: rulesAndProcedures===null||rulesAndProcedures===undefined?rulesAndProceduresPrevious==null? null:eventsDetails?.rules_and_procedures:rulesAndProcedures?.message.file_url,
        study_guide:studyGuideImage===null||studyGuideImage===undefined?studyGuidePreviousImage==null?null:eventsDetails?.study_guide:studyGuideImage?.message.file_url,
        full_experience_itinerary:fullExperienceItineraryImage===null||fullExperienceItineraryImage===undefined?fullExperiencePreviousImage==null?null:eventsDetails?.full_experience_itinerary:fullExperienceItineraryImage?.message.file_url,
        regular_event_itinerary:regularEventItineraryImage===null||regularEventItineraryImage===undefined?regularEventItineraryPreviousImage==null?null:eventsDetails?.regular_event_itinerary:regularEventItineraryImage?.message.file_url,
        event_topic_attachment:eventTopicAttachmenImage===null||eventTopicAttachmenImage===undefined?regularEventTopicAttachmenImage==null?null:eventsDetails?.event_topic_attachment:eventTopicAttachmenImage?.message.file_url,
      };

      const EditEvents = async (PassJson) => {
        console.log(PassJson);
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Events/${eventsDetails?.name}`,
              PassJson,
              config
            )
            .then((res) => {
              // console.log("create events", res);
              setSubmitDone(!submitDone);
              toast.success("Updated successfully");
            });
        } catch (e) {
          toast.error("Events updated failed");
          console.log(e);
        }
      };

      EditEvents(PassJson);
    },

    enableReinitialize: true,
  });

  //   create events
  const createEvents = useFormik({
    initialValues: {
      booking_reference_number: '',
      title:'',
      description:'',
      start_date: '',
      end_date: '',
      full_experience_end_date: '',
      event_topic_title:'',
      event_topic_description:'',
      city: '',
      country: '',
      status:'',
      venue:'',
      venue_details:'',
    },
    onSubmit: (values) => {
      var PassJson = {
        booking_reference_number: values.booking_reference_number,
        title: values.title,
        description: values.description,
        start_date: values.start_date,
        end_date: values.end_date,
        full_experience_end_date: values.full_experience_end_date,
        event_topic_title: values.event_topic_title,
        event_topic_description:editorValue,
        event_topic_attachment:eventTopicAttachmenImage?.message?.file_url,
        // full_experience_itinerary: values.full_experience_itinerary,
        city: values.city,
        country: values.country,
        status: values.status,
        venue: values.venue,
        venue_details: values.venue_details,
        main_picture: uploadFileResponse?.message?.file_url,
        rules_and_procedures: rulesAndProcedures?.message.file_url,
        full_experience_itinerary: fullExperienceItineraryImage?.message?.file_url,
        study_guide: studyGuideImage?.message?.file_url,
        regular_event_itinerary:regularEventItineraryImage?.message?.file_url
      };

      const createnewEvents = async (PassJson) => {
        console.log(PassJson);
        try {
          await axios
            .post(`${window.$domain}/api/resource/Events`, PassJson, config)
            .then((res) => {
              // console.log("create events", res);
              setSubmitDone(!submitDone);
              toast.success("Event created successfully");
              resetFormaCreateEvents()
            });
        } catch (e) {
          console.log(e);
          toast.error("Creation events failed");
        }
      };

      createnewEvents(PassJson);
    },

    enableReinitialize: true,
  });
const resetFormaCreateEvents=()=>{
       setFullExperienceItineraryImage(null)
        setRegularEventItineraryImage(null)
        setStudyGuideImage(null)
        setRulesAndProcedures(null)
        setEventTopicAttachmenImage(null)
        setUploadFileResponse("");
        setEditorValue('')
        createEvents.resetForm()
}

  const handleEditorChange = (content, editor) => {
    const textContent = editor.getContent({ format: 'text' });
    setEditorValue(textContent);
    console.log("Content was updated:", textContent);
  };

  const handleChangeupdate = (content, editor) => {
    const textContent = editor.getContent({ format: 'text' });
    setUpdateEditorValue(textContent);
    console.log("Content was updated:", textContent);
  };
  return (
    <>
    
      <div className="flex justify-between items-center mt-3  pr-3 ">
        {loadingSkeleton ? (
          <>
            <Skeleton width={80} height={40} />{" "}
            <Skeleton width={80} height={40} />{" "}
            
          </>
        ) : (
          <>
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              All Events
            </h1>
            <button
              type="button"
              className=" inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                setOpenEvent(true);
                setFullExperienceItineraryImage(null)
                setRegularEventItineraryImage(null)
                setStudyGuideImage(null)
                setRulesAndProcedures(null)
                setEventTopicAttachmenImage(null)
                setUploadFileResponse("");
              }}
            >
              Create New Event
            </button>
          </>
        )}
      </div>
      <div className="mt-1 divide-y py-1 pr-2 ">
        <p className="felx justify-end text-right font-semibold text-gray-600">
          {loadingSkeleton ? (
            <Skeleton width={40} height={20} />
          ) : (
            <>Events: {totalEvents} </>
          )}
        </p>
      </div>
      <div className="">
      <Toaster position="bottom-left" richColors />
     
       <div className="px-4">
         <div className="mt-2 flex flex-col max-h-screen">
         <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-5 lg:px-4">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y   divide-gray-300">
                  <thead className="bg-stone-100">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                      >
                        Name & Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        City
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Start
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        End
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      ></th>
                      <th
                        scope="col"
                        className="relative  py-3.5 pl-3 pr-4 sm:pr-4"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  {loadingSkeleton ?<tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     <div className="flex items-center">
                       <div
                         className="h-14 w-14 flex-shrink-0"
                        
                       >
                           <Skeleton borderRadius={40} width={50}  height={50}/>
                       </div>
                       <div className="ml-2">
                         <div
                         
                           className="font-medium cursor-pointer text-gray-900"
                         >
                            <Skeleton  width={90}  height={10}/>
                         </div>
                         <div className="text-gray-500">
                         <Skeleton  width={80}  height={10}/>
                         </div>
                       </div>
                     </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>

                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                   
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                    {allEvents?.map((person) => (
                      <tr key={person.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-4">
                          <div className="flex items-center">
                            <div className="h-12 w-12 flex-shrink-0">

                            <PrivateImage imageUrl={ person.main_picture} className={"h-12 w-12 rounded-full"}/> 
                                  
                             
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person.title}
                              </div>
                              {/* <div className="text-gray-500">
                                {person.owner}
                              </div> */}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">{person.city}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {" "}
                            {moment(person.start_date).format("LL")}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {moment(person.end_date).format("LL")}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       { person.status=="Active"? <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {person.status}
                          </span>: person.status=="Upcoming"? <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-400">
                            {person.status}
                          </span>: <span className="inline-flex rounded-full bg-red-200 px-2 text-xs font-semibold leading-5 text-red-800">
                            {person.status}
                          </span>}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.role}
                        </td> */}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-4">
                          <p
                            onClick={() => {
                              handleViewDetails(person);
                            }}
                            className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                          >
                            Edit<span className="sr-only">,</span>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Events
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 bg-indigo-500 sm:h-10 lg:h-20" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                               
                               
                                    {
                                      createEventsImage
                                        ? <PrivateImage imageUrl={ uploadFileResponse?.message?.file_url} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> :<PrivateImage imageUrl={  eventsDetails?.main_picture} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                        
                                    }
                                </div>
                              </div>
                            </div>
                            <div className="mt-3 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {eventsDetails?.title}
                                  </h3>
                                  <span className=""></span>
                                </div>
                                <p className="text-sm text-gray-500">{}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form
                          onSubmit={UpdateEvents.handleSubmit}
                          className="flex  flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="">
                            <div className="space-y-10 px-4">
                              <div className="border-b border-gray-900/10 pb-2 ">
                                <h2 className="text-base pt-4 font-semibold leading-7 text-gray-900">
                                  Hotel Booking
                                </h2>

                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                                  <div className="col-span-full">
                                    <label
                                      htmlFor="booking_reference_number"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Booking Reference Number
                                    </label>
                                    <div className="mt-2">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="booking_reference_number"
                                          id="booking_reference_number"
                                          onChange={UpdateEvents.handleChange}
                                          value={
                                            UpdateEvents.values
                                              .booking_reference_number
                                          }
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className=" flex flex-col gap-4 sm:col-span-7">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                      Event Information
                                    </h2>
                                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Main Picture</span>
                                      <div className="w-20 pt-2">
                                        {submitImage ? (
                                          <button
                                            disabled
                                            type="button"
                                            className="text-white bg-blue-500 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-500 dark:hover:bg-blue-500 dark:focus:ring-blue-500 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              className="inline w-4 h-4  text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                        
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className="sr-only "
                                              id="formFileSm"
                                              type="file"
                                              onClick={UpdateEvents.resetForm}
                                              onChange={(e) => {
                                                uploadFlightDocument(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Rules And Procedures</span>
                                      <div className="w-20 pt-2">
                                        {rulesAndProceduresloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm1"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm1"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                RulesAndProceduresImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {rulesAndProcedures===null||rulesAndProcedures===undefined?rulesAndProceduresPrevious===null||rulesAndProceduresPrevious===""||rulesAndProceduresPrevious===undefined?"":<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {rulesAndProceduresPrevious?.split("/").pop()}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setRulesAndProceduresPrevious(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>:<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {rulesAndProcedures?.message?.file_name}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setRulesAndProcedures(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>}
                                     
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Study Guide</span>
                                      <div className="w-20 pt-2">
                                        {rulesStudyGuideloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm2"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm2"
                                              type="file"
                                             
                                              onChange={(e) => {
                                                StudyGuideImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {studyGuideImage===null||studyGuideImage===undefined?studyGuidePreviousImage===""||studyGuidePreviousImage===null||studyGuidePreviousImage===undefined?"":<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {studyGuidePreviousImage?.split("/").pop()}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setStudyGuidePreviousImage(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>:<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {studyGuideImage?.message?.file_name}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setStudyGuideImage(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>}
                                      
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Full Experience Itinerary</span>
                                      <div className="w-20 pt-2">
                                        {fullExperienceItineraryloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm3"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm3"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                FullExperienceItineraryImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {fullExperienceItineraryImage===null||fullExperienceItineraryImage===undefined?fullExperiencePreviousImage===""||fullExperiencePreviousImage===null||fullExperiencePreviousImage===undefined?"":<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {fullExperiencePreviousImage?.split("/").pop()}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setFullExperiencePreviousImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>:<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {fullExperienceItineraryImage?.message.file_name}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setFullExperienceItineraryImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>}
                                    
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Regular Event Itinerary</span>
                                      <div className="w-20 pt-2">
                                        {regularEventItineraryloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm5"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm5"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                regularItineraryImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {regularEventItineraryImage===null||regularEventItineraryImage===undefined?regularEventItineraryPreviousImage===""||regularEventItineraryPreviousImage===null||regularEventItineraryPreviousImage===undefined?"":<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {regularEventItineraryPreviousImage?.split("/").pop()}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setRegularEventItineraryPreviousImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>:<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {regularEventItineraryImage?.message.file_name}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setRegularEventItineraryImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>}
                                    
                                    </div>
                                  </div>
                                   {/* event_topic_attachment */}
                                   <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Event Topic Attachment</span>
                                      <div className="w-20 pt-2">
                                        {eventTopicAttachmentloader ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm6"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm6"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                EventTopicAttachmentImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {eventTopicAttachmenImage===null||eventTopicAttachmenImage===undefined?regularEventTopicAttachmenImage===""||regularEventTopicAttachmenImage===null||regularEventTopicAttachmenImage===undefined?"":<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {regularEventTopicAttachmenImage?.split("/").pop()}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setRegularEventTopicAttachmenImage(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>:<div className="flex items-center  mt-1 gap-1" > <span
                                  
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {eventTopicAttachmenImage?.message.file_name}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setEventTopicAttachmenImage(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>}
                                      {/* {eventTopicAttachmenImage===null||eventTopicAttachmenImage===undefined?'':<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {eventTopicAttachmenImage?.message?.file_name}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setEventTopicAttachmenImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>} */}
                                    
                                    </div>
                                  </div>
                                </div>
                                    
                                  </div>
                                  <div className="col-span-7">
                                    <label
                                      htmlFor="title"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Title{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-2">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="title"
                                          id="title"
                                          onChange={UpdateEvents.handleChange}
                                          value={UpdateEvents.values.title}
                                          autoComplete="title"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-full">
                                    <label
                                      htmlFor="description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Description
                                    </label>
                                    <div className="mt-2">
                                      <textarea
                                        id="description "
                                        name="description "
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.description}
                                        rows={4}
                                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                      />
                                    </div>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">
                                      Write a few sentences about Event.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-2">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Timing
                                </h2>

                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="start_date"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Start Date
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.start_date}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="end_date"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      End Date
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.end_date}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="full_experience_end_date"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Full Experience End Date
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="full_experience_end_date"
                                        name="full_experience_end_date"
                                        type="date"
                                        onChange={UpdateEvents.handleChange}
                                        value={
                                          UpdateEvents.values
                                            .full_experience_end_date
                                        }
                                        autoComplete=""
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="sm:col-span-4">
                                  Full Experience Itinerary
                
                                <div className="mt-2 w-20">
                                <label
                                  for="formFileSm "
                                  className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                 Attach
                                </label>
                                <input
                                  className= " sr-only "
                                  id="formFileSm"
                                  type="file"
                                  onClick={UpdateEvents.resetForm}
                                  onChange={(e) => {
                                    
                                    uploadFlightDocument(e);
                                  }}
                                ></input>
                                </div>
                              </div> */}
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-10">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Location
                                </h2>

                                <div className="mt-3 grid  gap-x-6 gap-y-8 ">
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="country"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Country
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="text"
                                        name="country"
                                        id="country"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.country}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="city"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Ctiy
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.city}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="status"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Status
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="status"
                                        name="status"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.status}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option value="Upcoming">
                                          Upcoming
                                        </option>
                                        <option value="Closed">Closed</option>
                                        <option value="	Active">Active</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="venue"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Venue
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="venue"
                                        id="venue"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.venue}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="venue_details"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Venue Details
                                    </label>
                                    <div className="mt-2">
                                      <textarea
                                        id="venue_details"
                                        name="venue_details"
                                        onChange={UpdateEvents.handleChange}
                                        value={
                                          UpdateEvents.values.venue_details
                                        }
                                        rows={3}
                                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="event_topic_title"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                     Event Topic Title
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="event_topic_title"
                                        id="event_topic_title"
                                        onChange={UpdateEvents.handleChange}
                                        value={UpdateEvents.values.event_topic_title}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="event_topic_description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                   Event Topic Description
                                    </label>
                                    <div className="mt-2">
                                    <Editor
                                       initialValue={previousEditorValue}
                                      apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
                                      init={{
                                        height: "400",
                                        borderColor: "20px",
                                        branding: false,
                                        menubar: true,
                                        plugins:
                                          "print preview paste searchreplace autolink directionality visualblocks visualchars link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                        toolbar:
                                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                        image_advtab: true,
                                      }}
                                      onEditorChange={handleChangeupdate}
                                    />
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex over flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* creata events slider */}
      <Transition.Root show={openEvent} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenEvent}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Events
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpenEvent(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24  banner-back sm:h-10 lg:h-20" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                {uploadFileResponse?.message?.file_url ==
                                  null ||
                                uploadFileResponse?.message?.file_url ==
                                  "" ? null : (
                                  <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                    <PrivateImage imageUrl={ uploadFileResponse?.message?.file_url} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/>
                                  
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mt-3 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {/* {eventsDetails?.title} */}
                                  </h3>
                                  <span className=""></span>
                                </div>
                                <p className="text-sm text-gray-500">{}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form
                          onSubmit={createEvents.handleSubmit}
                          className="flex  flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="">
                            <div className="space-y-10 px-4">
                              <div className="border-b border-gray-900/10 pb-2 ">
                                <h2 className="text-base pt-4 font-semibold leading-7 text-gray-900">
                                  Hotel Booking
                                </h2>

                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                                  <div className="col-span-full">
                                    <label
                                      htmlFor="booking_reference_number"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Booking Reference Number
                                    </label>
                                    <div className="mt-2">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="booking_reference_number"
                                          id="booking_reference_number"
                                          onChange={createEvents.handleChange}
                                          value={
                                            createEvents.values
                                              .booking_reference_number
                                          }
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className=" flex flex-col gap-4 sm:col-span-7">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                      Event Information
                                    </h2>
                                    
                                    <div className="border-b border-gray-900/10 pb-2">
                                

                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Main Picture</span>
                                      <div className="w-20 pt-2">
                                        {submitImage ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm"
                                              type="file"
                                              onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                uploadFlightDocument(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                     
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Rules And Procedures</span>
                                      <div className="w-20 pt-2">
                                        {rulesAndProceduresloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm1"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm1"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                RulesAndProceduresImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {rulesAndProcedures===null||rulesAndProcedures===undefined?'':<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {rulesAndProcedures?.message?.file_name}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setRulesAndProcedures(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>}
                                     
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Study Guide</span>
                                      <div className="w-20 pt-2">
                                        {rulesStudyGuideloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm2"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm2"
                                              type="file"
                                             
                                              onChange={(e) => {
                                                StudyGuideImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {studyGuideImage===null||studyGuideImage===undefined?'':<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                   className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                 >
                                   {studyGuideImage?.message?.file_name}
                                 </span>
                                   <span
                                     type="button"
                                     className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                     onClick={() => setStudyGuideImage(null)}
                                   >
                                     <span className="sr-only">Close panel</span>
                                     <XMarkIcon
                                       className="h-4 w-4"
                                       aria-hidden="true"
                                     />
                                   </span></div>}
                                      
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Full Experience Itinerary</span>
                                      <div className="w-20 pt-2">
                                        {fullExperienceItineraryloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm3"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm3"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                FullExperienceItineraryImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {fullExperienceItineraryImage===null||fullExperienceItineraryImage===undefined?'':<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {fullExperienceItineraryImage?.message.file_name}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setFullExperienceItineraryImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>}
                                    
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Regular Event Itinerary</span>
                                      <div className="w-20 pt-2">
                                        {regularEventItineraryloder ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm4"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm4"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                regularItineraryImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {regularEventItineraryImage===null||regularEventItineraryImage===undefined?'':<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {regularEventItineraryImage?.message.file_name}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setRegularEventItineraryImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>}
                                    
                                    </div>
                                  </div>
                                  {/* event_topic_attachment */}
                                  <div className="sm:col-span-3">
                                  <div>
                                      {" "}
                                      <span className="">Event Topic Attachment</span>
                                      <div className="w-20 pt-2">
                                        {eventTopicAttachmentloader ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm6"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm6"
                                              type="file"
                                              // onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                EventTopicAttachmentImage(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {eventTopicAttachmenImage===null||eventTopicAttachmenImage===undefined?'':<div className="flex items-center  mt-1 gap-1" > <span
                                   
                                    className="rounded-full whitespace-nowrap text-ellipsis  overflow-hidden w-80  border-2 border-green-200 bg-green-100 px-3 text-green-600 text-xs font-semibold"
                                  >
                                    {eventTopicAttachmenImage?.message?.file_name}
                                  </span>
                                    <span
                                      type="button"
                                      className="rounded-md  bg-white text-gray-400 hover:text-red-500 focus:ring-2 focus:ring-red-500"
                                      onClick={() => setEventTopicAttachmenImage(null)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span></div>}
                                    
                                    </div>
                                  </div>
                                </div>
                              </div>
                                  </div>
                                  <div className="col-span-7">
                                    <label
                                      htmlFor="title"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Title{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-2">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="title"
                                          id="title"
                                          onChange={createEvents.handleChange}
                                          value={createEvents.values.title}
                                          autoComplete="title"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-full">
                                    <label
                                      htmlFor="description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Description
                                    </label>
                                    <div className="mt-2">
                                      <textarea
                                        id="description"
                                        name="description"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.description}
                                        rows={4}
                                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                      />
                                    </div>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">
                                      Write a few sentences about Event.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-2">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Timing
                                </h2>

                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="start_date"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Start Date
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.start_date}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="end_date"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      End Date
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.end_date}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="full_experience_end_date"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Full Experience End Date
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="full_experience_end_date"
                                        name="full_experience_end_date"
                                        type="date"
                                        onChange={createEvents.handleChange}
                                        value={
                                          createEvents.values
                                            .full_experience_end_date
                                        }
                                        autoComplete=""
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="sm:col-span-4">
                                  Full Experience Itinerary
                
                                <div className="mt-2 w-20">
                                <label
                                  for="formFileSm "
                                  className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                >
                                 Attach
                                </label>
                                <input
                                  className= " sr-only "
                                  id="formFileSm"
                                  type="file"
                                  onClick={UpdateEvents.resetForm}
                                  onChange={(e) => {
                                    
                                    uploadFlightDocument(e);
                                  }}
                                ></input>
                                </div>
                              </div> */}
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-10">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Location
                                </h2>

                                <div className="mt-3 grid  gap-x-6 gap-y-8 ">
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="country"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Country
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="text"
                                        name="country"
                                        id="country"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.country}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="city"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      City
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.city}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="status"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Status
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="status"
                                        name="status"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.status}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option >Select</option>
                                        <option value="Upcoming">Upcoming</option>
                                        <option value="Closed">Closed</option>
                                        <option value="	Active">Active</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="venue"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Venue
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="venue"
                                        id="venue"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.venue}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="venue_details"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Venue Details
                                    </label>
                                    <div className="mt-2">
                                      <textarea
                                        id="venue_details"
                                        name="venue_details"
                                        onChange={createEvents.handleChange}
                                        value={
                                          createEvents.values.venue_details
                                        }
                                        rows={3}
                                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>  
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="event_topic_title"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                     Event Topic Title
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="event_topic_title"
                                        id="event_topic_title"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.event_topic_title}
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-7">
                                    <label
                                      htmlFor="event_topic_description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                   Event Topic Description
                                    </label>
                                    <div className="mt-2">
                                    <Editor
                                      initialValue=""
                                      apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
                                      init={{
                                        height: "400",
                                        borderColor: "20px",
                                        branding: false,
                                        menubar: true,
                                        plugins:
                                          "print preview paste searchreplace autolink directionality visualblocks visualchars link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                        toolbar:
                                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                        image_advtab: true,
                                      }}
                                      onEditorChange={handleEditorChange}
                                    />
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() =>{ setOpenEvent(false)
                                resetFormaCreateEvents()}}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CreateEventa;
