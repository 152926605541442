import React, { useTransition } from "react";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";

import { useState, useCallback, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";

import { useFormik } from "formik";

import ReactQuill, { Quill } from "react-quill";
import Mention from "quill-mention";
import "react-quill/dist/quill.snow.css";

import Skeleton from "react-loading-skeleton";

import axios from "axios";
import { useParams } from "react-router-dom";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import PrivateImage from "../PrivateImage/PrivateImage";

Quill.register("modules/mention", Mention);
const modules = {
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function (searchTerm, renderList) {
      const values = [
        { id: 1, value: "{first_name}" },
        { id: 2, value: "{last_name}" },
        { id: 3, value: "{event_name}" },
      ];

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];

        for (let i = 0; i < values.length; i++) {
          if (
            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            matches.push(values[i]);
          }
        }

        renderList(matches, searchTerm);
      }
    },
  },
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
    ["link", "image", "video"],
  ],
};

var pre_final_string = "";
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};
const skeletonarry = [1, 2, 3, 4, 5, 6];

const EmailSend = () => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);
  const { eventID } = useParams();
  const [allEventRegisterations, setAllEventRegisterations] = useState([]);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [description, setDescription] = useState();
  const [data, setData] = useState([]);
  const [allDelegatesEmail, setAllDelegatesEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isPending, startTransition] = useTransition()

  const [editorValue, setEditorValue] = useState("");

  const handleChange = useCallback((value) => {
    const newValue = value.replace(/@/g, "");
    setEditorValue(newValue);
  }, []);
  console.log(editorValue);

  useEffect(() => {
    const fetchEventRegisteration = async () => {
      try {
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/internal-registrations?filters=[["event","=","${eventID}"]]`,
            config
          )
          .then((response) => {
            setAllEventRegisterations(response.data.data);
            console.log(response.data.data);

            setFilteredData(response.data.data);
            setSkeletonLoading(false);
          });
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK" || e.response.status == 404) {
        }
      }
    };
    fetchEventRegisteration();
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    startTransition(() => {
      setSearchTerm(searchTerm)
    })
    setSearchTerm(searchTerm);

    // Filter data based on search term
    const filteredData = allEventRegisterations.filter((item) =>
      item.delegate_profile.user.first_name.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filteredData);
  };
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const updatedRegistrations = allEventRegisterations.map((item) => {
      return { ...item, checked: checked };
    });
    setAllEventRegisterations(updatedRegistrations);
    setFilteredData(updatedRegistrations);
  };

  const handleItemChange = (e, itemId) => {
    const checked = e.target.checked;
    const updatedRegistrations = allEventRegisterations.map((item) => {
      if (item.name === itemId) {
        return { ...item, checked: checked };
      }
      return item;
    });
    setAllEventRegisterations(updatedRegistrations);
    setFilteredData(updatedRegistrations);

    // console.log("<><>><</>",updatedRegistrations.map((items)=>{items.name}));
  };
  useEffect(() => {
    const selectedItems = allEventRegisterations.filter((item) => item.checked);
  const name = selectedItems.map((items) => ({ name: items.delegate_email }));
  console.log(name);
  pre_final_string=name.map(obj => obj.name).join(',');
  console.log(pre_final_string);
  }, [allEventRegisterations])
  
  

  const handelpopup = () => {
    setOpen(true);
  };
  // Formik for delete

  const observersDelete = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      var jsontoPass = {
        users: pre_final_string,
        subject: values.subject,
        message: editorValue,
        event:eventID
      };
      const fetchAmbData = async (jsontoPass) => {
      
        try {
          await axios
            .post(
              `${window.$domain}/api/method/send-custom-email`,
              jsontoPass,
              config
            )
            .then((res) => {
              // console.log(res.data.data)
              // setIsSuccess(true)
              // setSubmitDone(!submitDone)
              // setTimeout(() => {
              //   setIsSuccess(false)
              // }, 2000);
            });
        } catch (e) {
          console.log(e);
        }
      };
      fetchAmbData(jsontoPass);
    },

    enableReinitialize: true,
  });

  return (
    <div className="space-y-2">
     <div className="overflow-hidden bg-green-50 shadow sm:rounded-lg">
      <div className="px-3 py-2 sm:px-3">
        <h3 className="text-base font-semibold leading-6 text-green-700">How to write an Email?</h3>
       
      </div>
      <div className="border-t border-gray-200 px-3 py-3 sm:px-3">
        
        <dl className=" flex flex-col gap-2 ">
           <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-green-600"></dt>
            <dd className="mt-1 text-sm text-gray-900">
            Write a standard email, but add a specific component wherever you wish to include delegate-specific information.
To automate personalized communications, you may choose from the following list of variables.
            </dd>
            </div>
            <div className="flex gap-y-2  flex-col">
            <div className="flex w-60 justify-between items-center ">
            <li className="text-sm  font-medium list-none text-green-600">1: First Name  </li>
            <dd className=" text-sm text-gray-700"> {"{"}first_name{"}"}</dd>
          </div>
          <div className="   flex w-60 justify-between items-center ">
            <li className="text-sm  list-none font-medium text-green-600">2: Last Name </li>
            <dd className=" text-sm text-gray-700"> {"{"}last_name{"}"} </dd>
          </div>
          <div className=" flex w-60 justify-between items-center ">
            <li className="text-sm list-none  font-medium text-green-600">3: Event name </li>
            <dd className=" text-sm text-gray-700"> {"{"}event_name{"}"}</dd>
          </div>
          <dd className="mt-1 text-sm text-gray-900">
          To use these variables easily you can just type {">"} this symbol and select the required variable.
            </dd>
            <dd className="mt-1 text-sm text-gray-900">
            You can also view an example email by clicking the button <span className=" text-sm text-blue-600 cursor-pointer underline hover:text-blue-800" onClick={handelpopup}> Here. </span>
            </dd>
         
            
           
      
          </div>
      
       
        </dl>
      </div>
    </div>
      <div className="grid grid-cols-6 gap-x-1 gap-y-3 md:grid-cols-6">
        <form
          onSubmit={observersDelete.handleSubmit}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4"
        >
          {" "}
          <div className="px-2 pt-2 pb-2">
            <label
              htmlFor="subject"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Subject <span className="required"> * </span>{" "}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="subject"
                id="subject"
                onChange={observersDelete.handleChange}
                value={observersDelete.values.subject}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
                aria-describedby="email-description"
              />
            </div>
          </div>
          <div className="px-2  h-96 ">
            <ReactQuill
              // style={{ width: 600, height: 290 }}
              value={editorValue}
              onChange={handleChange}
              modules={modules}
              theme="snow"
              style={{
                height: "310px",
                
                borderColor: "20px",
               
              }}
            />
          </div>
          <div className="flex items-center justify-end gap-x-3 border-t border-gray-900/10 px-4 py-2 mt-5 sm:px-8">
            {skeletonLoading ? (
              <>
                <Skeleton width={70} height={28} />
                <Skeleton width={70} height={28} />
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => setDescription(" ")}
                  className="text-sm rounded-md w-24 bg-gray-200 px-3 py-1.5 font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md w-24 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </>
            )}
          </div>
        </form>
        <form className="bg-white shadow-sm ring-1  ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className=" relative divide-gary-300 divide-">
          <div className="px-1 pb-2">
          {skeletonLoading ?<>  <div className="relative pt-4 px-2 flex items-start">
                <div className="flex h-6 items-center">
                <Skeleton  width={22}  height={22}/>
                </div>
                <div className="ml-2 text-lg leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    Select All
                  </label>
                </div>
              </div>

              <div className="mt-3">
                <input
                   disabled
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  aria-describedby="email-description"
                />
              </div></>:  <>  <div className="relative pt-4 px-2 flex items-start">
                <div className="flex h-6 items-center">
                  <input
                       type="checkbox"
                       checked={
                        allEventRegisterations.length > 0 &&
                        allEventRegisterations.every((item) => item.checked)
                       }
                       onChange={handleSelectAll}
                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-2 text-lg leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    Select All
                  </label>
                </div>
              </div>

              <div className="mt-3">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  aria-describedby="email-description"
                />
              </div></> }
            </div>
            {skeletonLoading? <fieldset className="h-96 absolute border-t-gray-800 border-2 scrollbar-hide overflow-x-auto">
              <legend className="sr-only">Notifications</legend>
              {skeletonarry?.map((person) => (
                <div className="space-y-5 pt-3  " key={person}>
                  <div className="relative   flex items-start">
                    <div className="flex px-3 items-center">
                      <div className="flex h-8 items-center">
                      <Skeleton  width={22}  height={22}/>
                      </div>
                      <div className="h-10 ml-2 w-10 flex-shrink-0">
                      <Skeleton borderRadius={40} width={40}  height={40}/>
                      </div>
                      <div className="ml-2  w-40">
                        <div className="font-medium whitespace-pre-line text-gray-900 cursor-pointer">
                        <Skeleton borderRadius={1} width={100}  height={10}/>
                        </div>
                        <div className="text-gray-500 whitespace-normal">
                        <Skeleton borderRadius={1} width={120}  height={10}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </fieldset>:<fieldset className="h-96  scrollbar-hide overflow-x-auto">
              <legend className="sr-only">Notifications</legend>
              {filteredData?.map((person) => (
                <div className="space-y-5 pt-3  " key={person.name}>
                  <div className="relative   flex items-start">
                    <div className="flex px-3 items-center">
                      <div className="flex h-6 items-center">
                        <input
                                    type="checkbox"
                                    checked={person.checked}
                                    onChange={(e) => handleItemChange(e, person.name)}
                        
                          className="h-5  cursor-pointer w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="h-10 ml-2 w-10 flex-shrink-0">

                      <PrivateImage imageUrl={ person.delegate_profile.user.user_image} className={"h-10 w-10 rounded-full"}/> 
                                  
                        
                      </div>
                      <div className="ml-1 w-48">
                        <div className="font-medium whitespace-pre-line text-gray-900 cursor-pointer">
                          {person.delegate_profile.user.first_name +
                            " " +
                            person.delegate_profile.user.last_name}
                        </div>
                        <div className="text-gray-500 whitespace-normal">
                          {person.delegate_profile.user.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </fieldset>}
          </div>
        </form>
      </div>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                <div className="sm:flex sm:items-start">
                  
                  <div className="mt-3 text-center  sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  
                    </Dialog.Title>
                    <div className="mt-2">
                      <dd className="text-sm leading-6 text-gray-600">
                        <dd> Example Email:</dd>
                        <dd> Dear {"{"}first_name{"}"} {"{"}last_name{"}"},</dd>
                        <dd> We hope you are in the best of your health. We are contacting you to inform you that certain aspects of your profile are missing and can result in SUSPENSION of your Participation at {"{"}event_name{"}"} if not completed within the next 24 hours.</dd>
<dd>Best Regards, </dd>
<dd>Best Diplomats</dd>
<dd> Tech Department</dd>


                      </dd>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
     

    </div>
  );
};

export default EmailSend;
