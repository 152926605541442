import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import BlankVoucher from '../../img/hotel_pass_bg_new_address.png'
import FontRoboto from '../../Roboto-Regular.ttf'
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit'
import QRCode from 'qrcode'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import FontRobotoBold from '../../Roboto-Bold.ttf'
  import { Document, Page, Text, View, StyleSheet,PDFViewer, Image, Font,PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';

const HotelPassCheckInTeam = () => {
  Font.register({
    family: 'Roboto',
    src: FontRobotoBold
  });
  const styles = StyleSheet.create({
    page: {
      
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },viewer: {
      width: 610, 
      height: 870,
    },
    bgimages: {
      height:842,
      position: 'absolute'
    },venu:{
      top:223,
      left:260,
      fontSize:12,
      marginTop:4
    },QRImage:{
      display:"none",
      top:155,
      left:460,
      position:'absolute',
      width:75
    },venudetails:{
        top:223,
        left:260,
        fontSize:12,
        marginTop:8 
    },roomdetails:{
        top:269,
        left:125,
        fontSize:12,
        marginTop:8 
    },guest:{
        top:325,
        left:290,
        fontSize:12,
        marginTop:8 
    },guestpassport:{
        top:336,
        left:290,
        fontSize:12,
        marginTop:8,
        fontFamily:'Roboto'
    },dob:{
        top:347,
        left:290,
        fontSize:12,
        marginTop:8 
    }
}
  );
    // States
    const [PDFFinalDoc, setPDFFinalDoc] = useState(null);
    const {eventId}= useParams();
    const {username}= useParams();
    const [currentUser,setCurrentUser]=useState();
    const [event,setEvent]=useState();
    const [eventRegData,setEventRegData]=useState();
    const [roomStatus,setroomStatus]=useState();
    const [loading,setLoading]=useState(false);
    const [qrUriImageFile,setqrUriImageFile]=useState();
    const [isRegisteredFinal,setIsRegisteredFinal]=useState();
    const [firstName,setFirstName]=useState();
    const [lastName,setLastName]=useState();
    const [startDate,setStartDate]=useState();
    const [endDate,setEndDate]=useState();
    


//  console.log(username);


    // Configrations
    var userEmail;
    var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

    // Get User Details

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                
                await axios
                .get(
                    
                    `https://dash.bestdiplomats.org/api/resource/Core Team Registration/${username}`,
                    config
                    )
                    .then((responseUser) => {
                // console.log(responseUser);
                setCurrentUser(responseUser.data.data)
                const firstNameToTitleCase = (firstname) => {
                  return firstname
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                };
                const secondNameToTitleCase = (secondname) => {
                  return secondname
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                };
                setFirstName(firstNameToTitleCase(responseUser.data.data.first_name))
                setLastName(secondNameToTitleCase(responseUser.data.data.last_name))
                setIsRegisteredFinal(window.$FWDurl+"/HotelValidate/"+eventId+"/"+responseUser.data.data.name);
                
            });
            
          } catch (e) {
            console.log(e);
          }
        };
        fetchUserData();
        
      }, []);
 
    
    //   Event Details
useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            "https://dash.bestdiplomats.org/api/resource/Events/" + eventId
          )
          .then((response) => {
            // console.log(response);
            setEvent(response.data.data);
            const fetchEventReg = async () => {
              try {
                setLoading(true);
                const result = await axios
                  .get(
                    `https://dash.bestdiplomats.org/api/resource/Core Team Registration/${username}`,
                    config
                  )
                  .then((responseReg) => {
                    // console.log("resfcwef",responseReg.data.data);
                    if(responseReg?.data?.data?.room_status==="Single Room"){

                      setroomStatus("1                                     Single Room                                         2")
                    }else{
                      setroomStatus("1                                     Twin Room                                         2")

                    }
                   
                    if (responseReg.data?.data?.hotel_checkin_date == "" || responseReg.data.data?.hotel_checkin_date == null || responseReg.data?.data?.hotel_checkout_date == "" || responseReg?.data?.data?.hotel_checkout_date == null) {
                      
                      if (responseReg.data?.data?.package=="Full Experience (Second Payment Pending)" || responseReg?.data?.data?.package=="Full Experience") {
                        setStartDate(response.data?.data?.start_date);
                        setEndDate(response.data?.data.full_experience_end_date)
                      }else{
                       
                        setStartDate(response.data.data.start_date);
                        setEndDate(response.data.data.end_date)
                      }
                    }else {
                     
                      setStartDate(responseReg?.data?.data?.hotel_checkin_date);
                      setEndDate(responseReg?.data?.data?.hotel_checkout_date);

                    }
                    
                    setLoading(false);
                  });
                setLoading(false);
              } catch (e) {
                console.log(e);
              }
            };
            fetchEventReg();
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventData();
  }, []);


  useEffect(() => {
    const generateQR = async text => {
      try {
        setqrUriImageFile(await QRCode.toDataURL(text,{
          color: {
            dark: '#000000',  // Blue dots
            light: '#0000' // Transparent background
          }
        }))
        
      } catch (err) {
        console.error(err)
      }
    }  
  generateQR(isRegisteredFinal);
    }, [isRegisteredFinal]);
  
   


  return (
    <div>
    {/* {loading ? null :<div className="rounded-md bg-green-50 p-4">
      <div className="flex">
      <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">This is verified document.</h3>
         
          
        </div>
      </div>
    </div>} */}
    
    <PDFDownloadLink document={ <Document>
    <Page size={[595,842]} >
    <Image src={BlankVoucher} style={styles.bgimages}></Image>
    <Image src={qrUriImageFile} style={styles.QRImage}></Image>
    
    <Text style={styles.venu}>{event?.venue}</Text>
    <Text style={styles.venudetails}>{event?.venue_details}</Text>
    <Text style={styles.venudetails}>{event?.booking_reference_number}</Text>
    <Text style={styles.venudetails}>{moment(startDate).format("LL")}</Text>
    <Text style={styles.venudetails}>{moment(endDate).format("LL")}</Text>
    <Text style={styles.roomdetails}>{roomStatus}</Text>
    <Text style={styles.guest}>{currentUser?.first_name+" "+currentUser?.last_name}</Text>
    <Text style={styles.guestpassport}>{""+currentUser?.passport_number}</Text>
    <Text style={styles.dob}>{currentUser?.birthday==null||currentUser?.birthday==undefined||currentUser?.birthday==""?"N/A":currentUser?.birthday}</Text>
      
    </Page>
  </Document>
  } fileName={`Invitation-${currentUser?.first_name}.pdf`}>{({ blob, url, loading, error }) =>
        loading ? ( <span className='group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Loading...</span>) : ( <span className='group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Download PDF</span>)
      }
  </PDFDownloadLink>
    <PDFViewer style={styles.viewer}>
    <Document>
    <Page size={[595,842]} >
    <Image src={BlankVoucher} style={styles.bgimages}></Image>
    <Image src={qrUriImageFile} style={styles.QRImage}></Image>
    <Text style={styles.venu}>{event?.venue}</Text>
    <Text style={styles.venudetails}>{event?.venue_details}</Text>
    <Text style={styles.venudetails}>{event?.booking_reference_number}</Text>
    <Text style={styles.venudetails}>{moment(startDate).format("LL")}</Text>
    <Text style={styles.venudetails}>{moment(endDate).format("LL")}</Text>
    <Text style={styles.roomdetails}>{roomStatus}</Text>
    <Text style={styles.guest}>{firstName+" "+lastName}</Text>
    <Text style={styles.guestpassport}>{""+currentUser?.passport_number}</Text>
    <Text style={styles.dob}>{currentUser?.birthday==null||currentUser?.birthday==undefined||currentUser?.birthday==""?"N/A":currentUser?.birthday}</Text>
      
    </Page>
  </Document>
  </PDFViewer>
    
    </div>
  )
}



export default HotelPassCheckInTeam