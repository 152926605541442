/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Avatar from "react-avatar-edit";
import { CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import Tag from "../../Tag/Tag";
import { useFormik } from "formik";

import Skeleton from "react-loading-skeleton";
import ExportCSV from "../../OperationsDashboard/ExportCSV";
import { Toaster, toast } from "sonner";
import axiosCacheAdapter from "axios-cache-adapter";
import PrivateImage from "../../PrivateImage/PrivateImage";

const Guests = () => {
  const { eventID } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [createEventsImage, setCreateEventsImage] = useState(false);
  const [submitImage, setSumbitImage] = useState(false);
  const [flightDocument, setFlightDocument] = useState(false);
  const [loaderFlightFile, setLoaderFlightFile] = useState(false);
  const [apiFilters, setApiFilters] = useState(`["event","=","${eventID}"]`);
  const [allEventRegisterations, setAllEventRegisterations] = useState(null);
  const [uploadFileResponse, setUploadFileResponse] = useState(null);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [isCropingPicture, setIsCropingPicture] = useState(false);

  const [uploadPictureResponse, setUploadPictureResponse] = useState(null);
  const [submitDone, setSubmitDone] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [open, setOpen] = useState(false);
  const [guestsDetail, setGuestsDetail] = useState("");
  const [isDeleteID, setDeleteID] = useState(null);
  const [isDelete, setDelete] = useState(false);
  const [updatedSuccess, setUpdatedSuccess] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Configrations
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  // States
  const [totalRegEntries, setTotalRegEntries] = useState();
  useEffect(() => {
    const fetchEventRegisteration = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            `${window.$domain}/api/resource/Guest Registration?fields=["*"]&filters=[["event","=","${eventID}"]]`,
            config
          )
          .then((response) => {
            // console.log("><><><>", response.data.data);

            setTotalRegEntries(response.data.data.length);
            setAllEventRegisterations(response.data.data);
            setLoadingSkeleton(false);
            if (response.data.data.length == []) {
              toast.error("No Data Found");
              setIsNotFound(true);
            }
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
        setTotalRegEntries(0);
        toast.error("No Data Found");
        setIsNotFound(true);
        setLoadingSkeleton(false);
      }
    };
    fetchEventRegisteration();
  }, [submitDone, updatedSuccess]);
  // Profile Picture Cropper
  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [profilePictureUploaded, setProfilePictureUploaded] = useState(false);
  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (view) => {
    setPreview(view);
  };
  const onPopUpClose = (view) => {
    setIsCropingPicture(false);
  };

  function cropFirst() {
    setIsCropingPicture(true);
  }
  const urlToFile = async (url, filename, mimeType) => {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };

  var result;
  function getCroppedImage() {
    // console.log(">>>");
    var random = Math.random().toString();
    var file = urlToFile(
      preview,
      "profilePicture" + random + ".png",
      "image/png"
    );
    file.then((a) => {
      // console.log(a);
      handlePictureUpload(a);
    });
    setIsCropingPicture(false);
  }
  function closePopup() {
    setPreview(null);
  }
  // Upload Picture File
  function handlePictureUpload(e) {
    let filePicture = e;
    const formData = new FormData();
    formData.append("file", filePicture);
    formData.append("doctype", "User");

    const uploadPictureApi = async () => {
      try {
        // setLoaderPassportFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responsePicture) => {
            // console.log("><><><>>>>>>></></></>", responsePicture.data);
            setUploadPictureResponse(responsePicture.data.message);
            setProfilePictureUploaded(true);
          });
      } catch (e) {
        console.log(e);
      }
    };
    uploadPictureApi();
  }
  // const [csvData, setCsvData] = useState();

  const AddNewGuests = () => {
    // setUploadPictureResponse(null)
    setPreview(null);
    setIsCropingPicture(false);
    setProfilePictureUploaded(false);
    setOpenEvent(true);
  };

  const newGuestsCreate = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      var PassJson = {
        first_name: values.first_name,
        last_name: values.last_name,
        passport_number:values?.passport_number,
        passport_expiry:values?.passport_expiry,
        event: eventID,
        gender: values.gender,
        package: values.package,
        image: uploadPictureResponse?.file_url,
        first_line: values?.first_line,
        second_line: values?.second_line,
      };

      const creatsGuests = async (PassJson) => {
        // console.log(PassJson);
        try {
          await axios
            .post(
              `${window.$domain}/api/resource/Guest Registration`,
              PassJson,
              config
            )
            .then((res) => {
              // console.log("create Guests", res);
              setSubmitDone(!submitDone);
              toast.success("Create New Guests Successfully");
            });
        } catch (e) {
          toast.error(" Create failed");
          console.log(e);
        }
      };

      creatsGuests(PassJson);
    },

    enableReinitialize: true,
  });
  // Formik for detail update
  const Observersdetailupdate = useFormik({
    initialValues: {
      package: guestsDetail?.package,
      passport_number:guestsDetail?.passport_number,
      passport_expiry:guestsDetail?.passport_expiry,
      first_line: guestsDetail?.first_line,
      second_line: guestsDetail?.second_line,
    },
    onSubmit: (values) => {
      var JSONforUpdate = {
        package: values.package,
        passport_number:values?.passport_number,
        passport_expiry:values?.passport_expiry,
        first_line: values?.first_line,
        second_line: values?.second_line,
      };
      const ObserversDetailUpdate = async () => {
        try {
          const result = await axios
            .put(
              `${window.$domain}/api/resource/Guest Registration/${guestsDetail?.name}`,
              JSONforUpdate,
              config
            )
            .then((response) => {
              // console.log(response, JSONforUpdate);
              toast.success("Updated successfully");
              setUpdatedSuccess(!updatedSuccess);
            });
        } catch (e) {
          console.log(e);
          toast.error(" Updated failed");
        }
      };
      ObserversDetailUpdate();
    },
    enableReinitialize: true,
  });
  // Formik for delete
  const observersDelete = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const result = await axios.delete(
          `${window.$domain}/api/resource/Guest Registration/${isDeleteID}`,
          config
        );
        // console.log(result);
        toast.success("Deleted successfully");
        setUpdatedSuccess(!updatedSuccess);
      } catch (e) {
        console.error(e);
        toast.error("Failed to delete");
      }
    },
    enableReinitialize: true,
  });

  const handleViewDetails = (e) => {
    setOpen(true);
    setGuestsDetail(e);
    console.log(e);
  };

  return (
    <>
      <div className="flex justify-end mt-3 gap-2">
        <Toaster position="bottom-left" richColors />
        {loadingSkeleton ? (
          <>
            <Skeleton width={80} height={40} />{" "}
          </>
        ) : (
          <div className="flex gap-1">
               {isNotFound ? (
                <button
                  disabled
                  className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 w-fit text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  All Guest Certificate{" "}
                  <ChevronDownIcon className="h-5 w-5" />
                </button>
              ) : (
                <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center hover:bg-blue-700 gap-x-1.5 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 ">
                  Download Guest Certificate Options
                    <ChevronDownIcon className="  h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>
          
                <Transition
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ focus }) => (
                          <a
                          href={`${window.$FWDurl}/bulkCertificateGuest/${eventID}`}
                          target="_blank"
                            className={classNames(
                              focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm hover:bg-gray-200'
                            )}
                          >
                            Certificate For Best Diplomats
                          </a>
                        )}
                      </Menu.Item>
                       <Menu.Item>
                        {({ focus }) => (
                          <a
                          href={`${window.$FWDurl}/bulkCertificateGuestImpactDirector/${eventID}`}
                          target="_blank"
                            className={classNames(
                              focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm hover:bg-gray-200'
                            )}
                          >
                            Certificate For Impact Directors
                          </a>
                        )}
                      </Menu.Item>
                    
                   
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
                // <a
                //   href={`${window.$FWDurl}/bulkCertificateGuest/${eventID}`}
                //   target="_blank"
                //   className="ml-3 w-fit inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                // >
                //   All Guest Certificate{" "}
                //   <CloudArrowDownIcon className="h-5 w-5" />
                // </a>
              )}
            {isNotFound ? (
              <button
                disabled
                className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 w-fit text-sm fmediumont- text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
               Download Guest ID Card Options
                  <ChevronDownIcon className="  h-5 w-5" aria-hidden="true" />
              </button>
            ) : (
              <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center hover:bg-blue-700 gap-x-1.5 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 ">
                Download Guest ID Card Options
                  <ChevronDownIcon className="  h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>
        
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ focus }) => (
                        <a
                        href={`${window.$FWDurl}/guestBulkIdCard/${eventID}`}
                        target="_blank"
                          className={classNames(
                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm hover:bg-gray-200'
                          )}
                        >
                        ID Cards For Best Diplomats
                        </a>
                      )}
                    </Menu.Item>
                     <Menu.Item>
                      {({ focus }) => (
                        <a
                        href={`${window.$FWDurl}/guestBulkIdCard-impact-director/${eventID}`}
                        target="_blank"
                          className={classNames(
                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm hover:bg-gray-200'
                          )}
                        >
                          ID Cards For Impact Directors
                        </a>
                      )}
                    </Menu.Item>
                  
                 
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
              // <a
              //   href={`${window.$FWDurl}/guestBulkIdCard/${eventID}`}
              //   target="_blank"
              //   className="ml-3 w-fit inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              // >
              //   All Guests ID Card <CloudArrowDownIcon className="h-5 w-5" />
              // </a>
            )}
            <button
              className="ml-3 w-fit inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                AddNewGuests();
                setUploadFileResponse("");
              }}
            >
              Add Guest
            </button>
          </div>
        )}
      </div>
      <div className="mt-1 py-1 divide-y ">
        <p className="felx justify-end text-right font-semibold text-gray-600">
          {loadingSkeleton ? (
            <Skeleton width={40} height={20} />
          ) : (
            <>Guests: {totalRegEntries} </>
          )}
        </p>
      </div>
      <div className="px-2">
        <div className="mt-2 flex flex-col max-h-screen">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
             
                {isNotFound ? ( <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
</svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>
                
                ) : ( <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-stone-100">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800 sm:pl-6"
                        >
                          Name
                        </th>
                        {/* <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Payment Package
                                  </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
                        >
                          Package
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    {loadingSkeleton ? (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-14 w-14 flex-shrink-0">
                                <Skeleton
                                  borderRadius={40}
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className="ml-2">
                                <div className="font-medium cursor-pointer text-gray-900">
                                  <Skeleton width={90} height={10} />
                                </div>
                                <div className="text-gray-500">
                                  {/* <Skeleton  width={80}  height={10}/> */}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {allEventRegisterations?.map((person) => (
                          <tr key={person.name}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0"  onClick={() => handleViewDetails(person)}>
                                <PrivateImage imageUrl={ person.image} className={"h-10 w-10 rounded-full cursor-pointer"}/> 
                                 
                                </div>
                                <div className="ml-4">
                                  <div
                                    className="font-medium text-gray-900 cursor-pointer"
                                    onClick={() => handleViewDetails(person)}
                                  >
                                    {person.first_name + " " + person.last_name}
                                  </div>
                                  <div className="text-gray-500"></div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.package}
                            </td>
                            <td className="relative whitespace-nowrap   text-right text-sm font-medium ">
                              <span
                                className="text-red-500 flex justify-center  hover:text-red-600"
                                onClick={() => {
                                  setDelete(true);
                                  setDeleteID(person.name);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
              </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* creata events slider */}
      <Transition.Root show={openEvent} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenEvent}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full ">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Guests
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpenEvent(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="items-center ">
                        <form
                          onSubmit={newGuestsCreate.handleSubmit}
                          className="flex  flex-col  bg-white"
                        >
                          <div className="">
                            <div className="space-y-10 px-4">
                              <div className=" pb-2 ">
                                <div className="mt-4 ">
                                  <div>
                                    <label
                                      htmlFor="user_image"
                                      className="font-light flex flex-row gap-2"
                                    >
                                      Picture
                                      <span className="text-red-500">*</span>
                                      {/* <Tooltip data="The background has to be white, without any patterns The picture should be colored, not black and white You must be in the center Your head, hair, ears, and shoulders have to be in the picture Your face should take up 70% – 80% of the picture The picture should be of good quality: there should be no shadows, over- or under-exposure, or glare The picture must show your real skin tone." /> */}
                                    </label>
                                    <div className="flex flex-row gap-4 items-center">
                                      <button
                                        onClick={cropFirst}
                                        type="button"
                                        style={{
                                          "background-color": "#4591DC",
                                        }}
                                        className="mt-4 text-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                      >
                                        Upload Profile Picture
                                      </button>
                                      {profilePictureUploaded ? (
                                        <span className="text-green-700 ">
                                          Picture Uploaded
                                        </span>
                                      ) : null}
                                    </div>
                                    <input
                                      id="user_image"
                                      name="user_image"
                                      type="file"
                                      // value={signUpDelegate.values.user_image}
                                      className="sr-only"
                                    />
                                  </div>{" "}
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-2">
                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first_name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      First Name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        required
                                        onChange={newGuestsCreate.handleChange}
                                        value={
                                          newGuestsCreate.values.first_name
                                        }
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="last_name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Last Name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        required
                                        onChange={newGuestsCreate.handleChange}
                                        value={newGuestsCreate.values.last_name}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="passport_number"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Passport Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="passport_number"
                                        id="last_name"
                                        required
                                        onChange={newGuestsCreate.handleChange}
                                        value={newGuestsCreate.values.passport_number}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="passport_expiry"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Passport Expiry
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="passport_expiry"
                                        id="passport_expiry"
                                        required
                                        onChange={newGuestsCreate.handleChange}
                                        value={newGuestsCreate.values.passport_expiry}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="package"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Package
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="package"
                                        name="package"
                                        onChange={newGuestsCreate.handleChange}
                                        value={newGuestsCreate.values.pakage}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option>Select</option>
                                        <option value="Accommodation">
                                          Accommodation
                                        </option>
                                        <option value="Non-Accommodation">
                                          Non-Accommodation
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="gender"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Gender
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="gender"
                                        name="gender"
                                        onChange={newGuestsCreate.handleChange}
                                        value={newGuestsCreate.values.gender}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option>Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first_line"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      ID Card First Line
                                    </label>
                                    <textarea
                                      type="text"
                                      name="first_line"
                                      id="first_line"
                                      maxlength="35"
                                      onChange={newGuestsCreate.handleChange}
                                      value={newGuestsCreate.values.first_line}
                                      required
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                    <span className=" font-extralight text-gray-600 align-middle content-center text-sm">
                                      Maximum character allowed 35
                                    </span>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="second_line"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      ID Card Second Line
                                    </label>
                                    <textarea
                                      type="text"
                                      name="second_line"
                                      id="second_line"
                                      value={newGuestsCreate.values.second_line}
                                      onChange={newGuestsCreate.handleChange}
                                      required
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                    <span className=" font-extralight text-gray-600 align-middle content-center text-sm">
                                      Maximum character allowed 42
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOpenEvent(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Register
                            </button>
                          </div>
                        </form>
                        {/* Cropper Popup */}
                        {isCropingPicture ? (
                          <div className="flex flex-col items-center justify-center mt-0 bg-gray-100 bg-opacity-75 overflow-y-auto overflow-x-hidden fixed top-10 right-0 left-0 z-50 md:inset-0 h-modal h-full">
                            <div className="flex justify-center">
                              <Avatar
                                width={200}
                                height={200}
                                onCrop={onCrop}
                                exportSize={300}
                                onClose={onClose}
                                src={src}
                              ></Avatar>
                            </div>
                            <div className="mt-2 w-48 flex justify-center items-center ">
                              {preview && (
                                <img
                                  className="border-4  border-blue-400 rounded"
                                  src={preview}
                                  alt="Cropped Image"
                                />
                              )}
                              {/* {console.log(preview)} */}
                            </div>
                            <div className="mt-12 flex flex-row justify-center ">
                              <button
                                onClick={getCroppedImage}
                                style={{ "background-color": "#4591DC" }}
                                className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Crop
                              </button>
                              <button
                                onClick={onPopUpClose}
                                className="rounded-md border  border-transparent ml-4 bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* slider team member details  */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Profile
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 banner-back sm:h-20 lg:h-28" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-full border-4 border-white">
                                <PrivateImage imageUrl={ guestsDetail?.image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                 
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {guestsDetail?.first_name}{" "}
                                    {guestsDetail.last_name}
                                  </h3>
                                </div>
                                {/* <p className="text-sm text-gray-500">{guestsDetail.user?.email}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Download ID Card
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center hover:bg-blue-700 gap-x-1.5 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 ">
                View and Download ID Card Options
                  <ChevronDownIcon className="  h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>
        
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ focus }) => (
                        <a
                        href={
                          window.$FWDurl +
                          "/guestIdCard/" +
                          guestsDetail.name
                        }
                        target="_blank"
                          className={classNames(
                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm hover:bg-gray-200'
                          )}
                        >
                     ID Card For Best Diplomats
                        </a>
                      )}
                    </Menu.Item>
                     <Menu.Item>
                      {({ focus }) => (
                        <a
                        href={
                          window.$FWDurl +
                          "/guestIdCard-impact-director/" +
                          guestsDetail.name
                        }
                        target="_blank"
                          className={classNames(
                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm hover:bg-gray-200'
                          )}
                        >
                        ID Card For Impact Directors
                        </a>
                      )}
                    </Menu.Item>
                  
                 
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
                                {/* <a
                                  className="ml-3 w-fit inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  href={
                                    window.$FWDurl +
                                    "/guestIdCard/" +
                                    guestsDetail.name
                                  }
                                  target="_blank"
                                >
                                  View and Download ID Card
                                </a> */}
                              </dd>
                            </div>
                            <form
                              onSubmit={Observersdetailupdate.handleSubmit}
                              className="flex flex-col divide-y divide-gray-200 gap-2"
                            >
                            <div className="sm:flex sm:px-6 sm:py-5">

                                  
<dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
Passport Number
</dt>
<dd className="mt-1 text-sm text-gray-900 sm:col-span-3 flex justify-center items-center gap-3 sm:mt-0 sm:ml-6">


  <label
    htmlFor="passport_number"
    className="sr-only"
  >
    
  </label>
  <div className="mt-2">
    <input
      type="text"
      name="passport_number"
      id="passport_number"
      required
      onChange={Observersdetailupdate.handleChange}
      value={Observersdetailupdate.values.passport_number}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    />
  </div>
  </dd>

</div>
                                  <div className="sm:flex sm:px-6 sm:py-5">

                                  
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Expiry
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 flex justify-center items-center gap-3 sm:mt-0 sm:ml-6">

                                  
                                    <label
                                      htmlFor="passport_expiry"
                                      className="sr-only"
                                    >
                                      
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="passport_expiry"
                                        id="passport_expiry"
                                        required
                                        onChange={Observersdetailupdate.handleChange}
                                        value={Observersdetailupdate.values.passport_expiry}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    </dd>
                                  
                                  </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Payment Package
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 flex justify-center items-center gap-3 sm:mt-0 sm:ml-6">
                                  <label
                                    htmlFor="package"
                                    className="sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="package"
                                    id="package"
                                    value={Observersdetailupdate.values.package}
                                    onChange={
                                      Observersdetailupdate.handleChange
                                    }
                                    required
                                    className="mt-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="	Non-Accommodation">
                                      {" "}
                                      Non-Accommodation
                                    </option>
                                    <option value="	Accommodation">
                                      Accommodation{" "}
                                    </option>
                                  </select>
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  First Line
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <div className="sm:col-span-3">
                                    <textarea
                                      id="first_line"
                                      name="first_line"
                                      rows={3}
                                      className="block w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      value={
                                        Observersdetailupdate.values.first_line
                                      }
                                      onChange={
                                        Observersdetailupdate.handleChange
                                      }
                                    />
                                    <span className=" font-extralight text-gray-600 align-middle content-center text-sm">
                                      Maximum character allowed 35
                                    </span>
                                  </div>
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Second Line
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <div className="sm:col-span-3">
                                    <textarea
                                      id="second_line"
                                      name="second_line"
                                      value={
                                        Observersdetailupdate.values.second_line
                                      }
                                      onChange={
                                        Observersdetailupdate.handleChange
                                      }
                                      rows={3}
                                      className="block w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                    <span className=" font-extralight text-gray-600 align-middle content-center text-sm">
                                      Maximum character allowed 42
                                    </span>
                                  </div>
                                </dd>
                              </div>

                              <div className="sm:flex flex justify-end sm:px-6 sm:py-5">
                                <button
                                  type="submit"
                                  className="group relative flex w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  Update
                                </button>
                              </div>
                            </form>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={isDelete} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDelete}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure want to DELETE?
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={observersDelete.handleSubmit}
                    className="mt-5 sm:mt-6"
                  >
                    <button
                      type="submit"
                      className="inline-flex w-full text-lg justify-center rounded-md bg-red-400 px-3 py-2 font-semibold text-white  hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      onClick={() => setDelete(false)}
                    >
                      Delete
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Guests;
