import React from "react";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { useFormik } from "formik";
import moment from "moment";
import {
  XMarkIcon,
  CheckCircleIcon,
  XCircleIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition, Switch } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Done from "../Alerts/Done";
import Papa from "papaparse";
import Tag from "../Tag/Tag";
import Registration from "../Registration/Registration";
import { ClearLocalStorage } from "../ClearLocalStorage";
import PrivateImage from "../PrivateImage/PrivateImage";

const SelfCheckinHotel = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { eventID } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUPImage, setPopUPImage] = useState(false);
  const [popcheckinselfieImage, setPopcheckinselfieImage] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [personDetail, setPersonDetail] = useState(null);
  const [failureReason, setFailureReason] = useState(false);
  const [UpdatePopUp, setUpdatePopUp] = useState(false);
  const [UpdateData, setUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const [apiFilters, setApiFilters] = useState(`["event","=","${eventID}"]`);
  const [enabled, setEnabled] = useState(false);
  const [enabledDetail, setEnabledForDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [allRoomSharing, setAllRoomSharing] = useState(null);
  const [totalRoomCount, setTotalRoomCount] = useState(0);
  const tableRef = useRef(null);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const limitPageLength = 50;
    console.log("apiFilters", apiFilters);
    const fetchRoomSharing = async () => {
      try {
        setLoading(true);

        setIsLoading(true);
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/fetch-self-checkins?limit_start=${
              (page - 1) * limitPageLength
            }&limit_page_length=${limitPageLength}&filters=[${apiFilters}]`,
            config
          )
          .then((response) => {
            console.log(">>>>> room", response.data.data);
            setIsNotFound(false);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 30);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            setTotalRoomCount(response.data.data.length);
            setAllRoomSharing(response.data.data);
            setLoadingSkeleton(false);
            setIsLoading(false);
            if (response.data.data.length == []) {
              toast.error("No Data Found");
              setLoadingSkeleton(true);
              setIsNotFound(true);
            }
          });
        setLoading(false);
      } catch (e) {
        if (
          JSON.parse(JSON.stringify(e)).message ==
          "Request failed with status code 403"
        ) {
          ClearLocalStorage(JSON.parse(JSON.stringify(e)));
        } else {
          console.log(JSON.parse(JSON.stringify(e)));
        }
        toast.error("No Data Found");
        setLoadingSkeleton(true);
        setIsNotFound(true);
      }
    };
    fetchRoomSharing();
  }, [page, UpdateData, enabled, apiFilters]);
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
    console.log("22", page);
  };
  const handleClick = (number) => {
    // setCurrentPage(number);
    scroll();
    scrollToTop();
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    scrollToTop();
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop();
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop();
      }
    }
  };

  const handleChangeIfImage = () => {
    setPopUPImage(true);
  };
  const handleChangeselfiesImage = () => {
    setPopcheckinselfieImage(true);
  };
  const [passPortExpire, setPassPortExpire] = useState("green");
  const Handleviewdetail = (person) => {
    setPersonDetail(person);
    const expiryDate = new Date(person?.passport_expiry);
    const currentDate = new Date();
    if (expiryDate > currentDate) {
      setPassPortExpire("green");
    } else {
      setPassPortExpire("red");
    }
    console.log("person", person);
    if (person.bag_allocated == 1) {
      setEnabledForDetail(true);
    } else {
      setEnabledForDetail(false);
    }
    if (
      person.failure_reason == null ||
      person.failure_reason == undefined ||
      person.failure_reason == ""
    ) {
      setFailureReason(false);
    } else {
      setFailureReason(true);
    }

    setOpen(true);
  };
  const handleDownload = () => {
    const newData = allRoomSharing?.map((item) => ({
      Delegate: item?.user?.first_name + " " + item?.user?.last_name,
      Passport_Gender: item?.passport_gender,
      Passport_Birthday: item?.passport_birthday,
      Payment_Package: item.payment_package,
      Face_Similarity_Score: item?.face_similarity_score,
      Passport_Number: item?.passport_number,
      Passport_Surname: item?.passport_surname,
      Checkin_Status: item?.checkin_status,
      Passport_Expiry: item?.passport_expiry,
      Passport_Nationality: item?.passport_nationality,
      Passport_Country: item?.passport_country,
      Room_Allocation_Type: item?.room_allocation_type,
      Roommate_Request_Status:
        item?.roommate_request_status == null ||
        item?.roommate_request_status == undefined
          ? "N/A"
          : item?.roommate_request_status,
      Allocated_Room_Number: item?.hotel_allocated_room_number,
      Passport_Front_Picture:
        "https://dash.bestdiplomats.org" + item?.passport_front_upload,
      Checkin_Selfie_Picture:
        "https://dash.bestdiplomats.org" + item?.checkin_selfie,
    }));

    const csv = Papa.unparse(newData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Self Checkin For Hotel.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleKeyPress = (key, e) => {
    if (e.key === "Enter") {
      setEditedData({});
    }
  };

  const [editedData, setEditedData] = useState({});
  const [editedID, setEditedID] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const handleChange = (key, value) => {
    if (value == "") {
      setEditedID(null);
      setEditedValue("");
      setEditedData({});
    } else {
      setEditedID(key);
      setEditedValue(value);
      setEditedData({ ...editedData, [key]: value });
    }
  };
  const [isLoader, setIsLoader] = useState(false);
  const UpdateRoomStatus = useFormik({
    initialValues: {
      checkin_status: personDetail?.checkin_status,
    },
    onSubmit: (values) => {
      const JSONforUpdate = {
        checkin_status: values.checkin_status,
      };
      console.log("JSONforUpdate", JSONforUpdate);

      const UpdateStatus = async () => {
        setIsLoader(true);
        try {
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Self Checkin/${personDetail.name}`,
              JSONforUpdate,
              config
            )
            .then((response) => {
              console.log(response.data.data);
              toast.success("Updated successfully");
              setUpdateData(!UpdateData);
              setIsLoader(false);
            });
        } catch (e) {
          console.log(e);
          toast.error("updated failed");
          setIsLoader(false);
        }
      };
      UpdateStatus();
    },
    enableReinitialize: true,
  });

  const handleToggleBagAssign = async (person) => {
    if (person.bag_allocated == 0) {
      var UpdateJson = {
        bag_allocated: 1,
      };
      const updateBagAssignStatus = async () => {
        try {
          setLoading(true);
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Self Checkin/${person.name}`,
              UpdateJson,
              config
            )
            .then((response) => {
              setEnabled(!enabled);
              setEnabledForDetail(true);
            });
          setLoading(false);
        } catch (e) {
          console.log(e);
          if (e.code == "ERR_NETWORK") {
            setIsNotFound(true);
          }
        }
      };

      toast.promise(updateBagAssignStatus(), {
        loading: "Loading...",
        success: "Assigned Bag",
        error: "Error Assigning Bag",
      });
    } else if (person.bag_allocated == 1) {
      var UpdateJson = {
        bag_allocated: 0,
      };
      const updateBagAssignStatus = async () => {
        try {
          setLoading(true);
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Self Checkin/${person.name}`,
              UpdateJson,
              config
            )
            .then((response) => {
              setEnabled(!enabled);
              setEnabledForDetail(false);
            });
          setLoading(false);
        } catch (e) {
          console.log(e);
          if (e.code == "ERR_NETWORK") {
            setIsNotFound(true);
          }
        }
      };
      toast.promise(updateBagAssignStatus(), {
        loading: "Loading...",
        success: "Unassigned Bag",
        error: "Error Unassigning Bag",
      });
    }
  };
  const applyFilters = useFormik({
    initialValues: {
      roommate_request_status: "",
      checkin_status: "",
      room_allocation_type: "",
      random_roommate: "",
      hotel_allocated_room_number: "",
    },
    onSubmit: (values) => {
      console.log(values);
      if (
        values.checkin_status != "" ||
        values.checkin_status != "" ||
        values.room_allocation_type != "" ||
        values.hotel_allocated_room_number != "" ||
        values.random_roommate != ""
      ) {
        setLoadingSkeleton(true);
      }
      setApiFilters(`["event","=","${eventID}"]`);
      setCurrentPage(1);
      // First Name % addition

      if (values.checkin_status) {
        if (values.checkin_status != "All") {
          setApiFilters((prevState) => [
            prevState + `,["checkin_status","=","${values.checkin_status}"]`,
          ]);
        }
      }
      if (values.roommate_request_status) {
        if (values.roommate_request_status != "All") {
          setApiFilters((prevState) => [
            prevState +
              `,["roommate_request_status","=","${values.roommate_request_status}"]`,
          ]);
        }
      }

      if (values.random_roommate) {
        if (values.random_roommate != "All") {
          setApiFilters((prevState) => [
            prevState + `,["random_roommate","=","${values.random_roommate}"]`,
          ]);
        }
      }
      if (values.room_allocation_type) {
        if (values.room_allocation_type != "All") {
          setApiFilters((prevState) => [
            prevState +
              `,["room_allocation_type","=","${values.room_allocation_type}"]`,
          ]);
        }
      }
      if (values.hotel_allocated_room_number) {
        if (values.hotel_allocated_room_number.length > 0) {
          setApiFilters((prevState) => [
            prevState +
              `,["hotel_allocated_room_number","=","${values.hotel_allocated_room_number}"]`,
          ]);
        }
      }
      // if (values.roommate_request_status!="") {
      //   setApiFilters((prevState) => [
      //     prevState + `,["roommate_request_status","=","${values.roommate_request_status}"]`,
      //   ]);
      // }  if (values.checkin_status!="") {
      //   setApiFilters((prevState) => [
      //     prevState + `,["checkin_status","=","${values.checkin_status}"]`,
      //   ]);
      // }
    },

    enableReinitialize: true,
  });
  const ResetRoomateStatus = async (UpdateJson) => {
    try {
      setLoading(true);
      const result = await axios
        .post(
          `https://dash.bestdiplomats.org/api/method/refresh-checkin`,{id:personDetail?.name},
          config
        )
        .then((response) => {
        //  console.log(response.data.data);
         toast.success("Updated successfully");
         setUpdateData(!UpdateData);
         setLoading(false);
         setUpdatePopUp(false);
        });
    } catch (e) {
      console.log(e.response);
      setLoading(false);
      if (e?.response?.status==403) {
        toast.error(e.response.data.exception)  
      }else{
        toast.error("Try again later")
      }
    }
  };
  return (
    <>
      <div className="flex justify-end mt-1 pt-2 gap-2">
        <form onSubmit={applyFilters.handleSubmit}>
          <div className="mt-4 flex justify-end gap-3 pb-1 items-end flex-wrap sm:mt-0 sm:ml-0 sm:flex-none">
            <div className="flex flex-col gap-y-0.5 items-start">
              <label htmlFor="checkin_status" className=" text-sm ">
                Checkin Status
              </label>
              <select
                id="checkin_status"
                name="checkin_status"
                className=" block w-40 rounded-md border-gray-300 py-2 pl-2 pr-6  text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.checkin_status}
              >
                <option value="All">All</option>
                <option value="Waiting for Passport">
                  Waiting for Passport
                </option>
                <option value="Face Matched">Face Matched</option>
                <option value="Passport Approved">Passport Approved</option>
                <option value="Arrived At Destination">
                  Arrived At Destination
                </option>
                <option value="Roommate Selected">Roommate Selected</option>
                <option value="Room Allocated">Room Allocated</option>
              </select>
            </div>
            <div className="flex flex-col  gap-y-0.5 items-start">
              <label htmlFor="roommate_request_status" className="text-sm">
                Roommate Request
              </label>
              <select
                id="roommate_request_status"
                name="roommate_request_status"
                className=" block w-40 rounded-md border-gray-300 py-2 pl-2 pr-6  text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.roommate_request_status}
              >
                <option value="All">All</option>
                <option value="None">None</option>
                <option value="Request Sent">Request Sent</option>
                <option value="Request Approved">Request Approved</option>
                <option value="Request Pending">Request Pending</option>
                <option value="Random Assigned">Random Assigned</option>
              </select>
            </div>
            <div className="flex flex-col  gap-y-0.5 items-start">
              <label htmlFor="random_roommate" className="text-sm">
                Random Roommate
              </label>
              <select
                id="random_roommate"
                name="random_roommate"
                className=" block w-40 rounded-md border-gray-300 py-2 pl-5 pr-6 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.random_roommate}
              >
                <option value="All">All</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className="flex flex-col  gap-y-0.5 items-start">
              <label htmlFor="room_allocation_type" className="text-sm">
                Room Allocation Type
              </label>
              <select
                id="room_allocation_type"
                name="room_allocation_type"
                className=" block w-40 rounded-md border-gray-300 py-2 pl-5 pr-6 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.room_allocation_type}
              >
                <option value="All">All</option>
                <option value="Single Room">Single Room</option>
                <option value="Twin Room">Twin Room</option>
              </select>
            </div>

            <button
              type="button"
              onClick={() => {
                applyFilters.resetForm();
                setPage(1);
                setApiFilters(`["event","=","${eventID}"]`);
              }}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Clear Filter
            </button>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Apply Filter
            </button>
            <div className="flex justify-end items-end  ">
              <div className="flex gap-4 items-start">
                <div className="relative w-[20rem]">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="pointer-events-none h-5 w-5 text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="hotel_allocated_room_number"
                    name="hotel_allocated_room_number"
                    // required
                    placeholder="Search by Room Number"
                    onChange={applyFilters.handleChange}
                    value={applyFilters.values.hotel_allocated_room_number}
                    className="bg-white border w-full border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-[#3056D3] focus:border-[#3056D3] block  pl-8 p-2 "
                  />
                </div>

                <button
                  type="submit"
                  className="inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Search
                </button>
              </div>
              <div className="mt-3.5 ml-3 flex justify-end items-center  sm:mt-0  sm:flex-none">
                {loadingSkeleton ? (
                  <>
                    <Skeleton width={124} height={38} />{" "}
                  </>
                ) : (
                  <button
                    type="button"
                    className=" inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => handleDownload()}
                  >
                    Download CSV
                  </button>
                )}
              </div>
            </div>
            {/* <button
              onClick={() => {
                handleAddRoom();
              }}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Room
            </button> */}
          </div>
        </form>
      </div>
      <div className="py-1 mt-0.5">
        <p className="felx justify-end text-right font-semibold">
          {loadingSkeleton ? (
            <>
              <Skeleton width={40} height={20} />{" "}
            </>
          ) : (
            <> Checkins: {totalRoomCount}</>
          )}
        </p>
      </div>
      <div className="px-2">
        <div className="mt-2 flex flex-col max-h-screen">
          <div
            ref={tableRef}
            className="-my-2 -mx-2  overflow-x-auto sm:-mx-5 lg:-mx-5"
          >
            <div className="inline-block min-w-full py-2 align-middle md:px-1 lg:px-1">
              {isNotFound ? (
                <div className="h-screen flex justify-center flex-col items-center ">
                  <svg
                    fill="none"
                    className="w-40 h-40 text-gray-400"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>{" "}
                  <span className=" text-lg text-gray-400"> No Data Found</span>
                </div>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {" "}
                  <table className="min-w-full divide-y  divide-gray-300">
                    <thead className="bg-stone-100">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                        >
                          Delegate
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Checkin At
                        </th>{" "}
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Bag Allocated
                        </th>{" "}
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Room Number
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Room Type
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Roommate Request
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Representing Country
                        </th>{" "}
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Payment Package
                        </th>
                        {/* <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Hotel Allocated Room Number
                        </th> */}
                        <th
                          scope="col"
                          className="relative sr-only py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    {loadingSkeleton ? (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-4">
                            <div className="flex items-center">
                              <div className="h-14 w-14 flex-shrink-0">
                                <Skeleton
                                  borderRadius={40}
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className="ml-2">
                                <div className="font-medium cursor-pointer text-gray-900">
                                  <Skeleton width={90} height={10} />
                                </div>
                                <div className="text-gray-500">
                                  <Skeleton width={80} height={10} />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              <Skeleton />
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton />
                          </td>{" "}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton />
                          </td>{" "}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton />
                          </td>{" "}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              <Skeleton />
                            </div>
                          </td>{" "}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              <Skeleton />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {allRoomSharing?.map((person, index) => (
                          <tr key={person.name}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-4">
                              <div className="flex items-center">
                                <div
                                  className="h-10 w-10 flex-shrink-0"
                                  onClick={() => {
                                    Handleviewdetail(person);
                                  }}
                                >
                                  <PrivateImage
                                    imageUrl={person?.user?.user_image}
                                    className={
                                      "h-10 w-10 rounded-full cursor-pointer"
                                    }
                                  />
                                </div>
                                <div className="ml-4">
                                  <div
                                    className="font-medium text-gray-900 cursor-pointer"
                                    onClick={() => {
                                      Handleviewdetail(person);
                                    }}
                                  >
                                    {person?.user?.first_name +
                                      " " +
                                      person?.user?.last_name}
                                  </div>
                                  <div className="text-gray-500">
                                    {person?.user?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {moment(person?.checkin_timestamp).format("lll")}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {person?.checkin_status}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              <Switch
                                checked={
                                  person.bag_allocated == 1 ? true : false
                                }
                                onClick={() => handleToggleBagAssign(person)}
                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    person.bag_allocated == 1
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                                  )}
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    person.bag_allocated == 1
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {person?.hotel_allocated_room_number == null ||
                              person?.hotel_allocated_room_number == ""
                                ? "N/A"
                                : person.hotel_allocated_room_number}
                            </td>{" "}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {person?.room_allocation_type}
                            </td>
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              <form onSubmit={UpdateRoomStatus.handleSubmit}>
                                <p
                                  className="my-0.5 text-sm text-gray-500"
                                  id="email-description"
                                >
                                  Allocated Room Number:{" "}
                                  <span className="text-sm text-gray-800">
                                    {person.hotel_allocated_room_number}
                                  </span>
                                </p>
                                <input
                                  className="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  type="number"
                                  value={editedData[person.name]}
                                  onClick={() => setEditedData({})}
                                  onBlur={() => setEditedData({})}
                                  onChange={(e) =>
                                    handleChange(person.name, e.target.value)
                                  }
                                />
                              </form>
                            </td> */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {person?.roommate_request_status}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {person?.event_registration?.representing_country}
                            </td>{" "}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {person?.event_registration?.payment_package}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isNotFound ? null : totalRoomCount >= 50 ? (
        <div className="flex items-center justify-center mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
          {isLoading && (
            <button
              type="button"
              disabled
              className="rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Loading...
            </button>
          )}
          {!isLoading && (
            <>
              <button
                type="button"
                onClick={handleLoadMore}
                disabled={isLoading}
                className="rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Load More
              </button>
              <button
                type="button"
                onClick={handleClick}
                className="rounded-md banner-back px-3 ml-4 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <ChevronUpIcon class="h-6 w-6 " />
              </button>
            </>
          )}
        </div>
      ) : (
        ""
      )}
      {/* nationalty for Filters */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Details
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-14  sm:h-14 lg:h-14 banner-back" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg ">
                                  {" "}
                                  <PrivateImage
                                    imageUrl={personDetail?.user?.user_image}
                                    className={
                                      "h-24 w-24  flex-shrink-0 sm:h-24 sm:w-24 lg:h-24 lg:w-24"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 sm:ml-5 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-xl">
                                    {personDetail?.user?.first_name +
                                      " " +
                                      personDetail?.user?.last_name}
                                  </h3>
                                </div>
                                <p className="text-sm text-gray-500">
                                  {personDetail?.user?.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Front Upload
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {" "}
                                {personDetail?.passport_front_upload ? (
                                  personDetail?.passport_front_upload.endsWith(
                                    ".pdf"
                                  ) ? (
                                    <div>
                                      <a
                                        className="inline-flex items-center gap-x-1.5 rounded-md    shadow-sm  bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          personDetail?.passport_front_upload
                                        }
                                        target="_blank"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 mr--0.5"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => handleChangeIfImage()}
                                      className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-44 lg:w-full"
                                    >
                                      <PrivateImage
                                        imageUrl={
                                          personDetail?.passport_front_upload
                                        }
                                        className={
                                          "h-24 w-24  flex-shrink-0 sm:h-full sm:w-full object-cover lg:h-full lg:w-full"
                                        }
                                      />
                                    </div>
                                  )
                                ) : (
                                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                    Missing
                                  </span>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Checkin Selfie
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {" "}
                                {personDetail?.checkin_selfie ? (
                                  <div
                                    onClick={() => handleChangeselfiesImage()}
                                    className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-44 lg:w-full"
                                  >
                                    {" "}
                                    <PrivateImage
                                      imageUrl={personDetail?.checkin_selfie}
                                      className={
                                        "h-24 w-24  sm:h-full sm:w-full object-cover lg:h-full lg:w-full"
                                      }
                                    />{" "}
                                  </div>
                                ) : (
                                  <span className="inline-flex rounded-full bg-red-100 px-3 py-1 text-xs font-semibold leading-5 text-red-800">
                                    Missing
                                  </span>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Bag Allocated
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <Switch
                                  checked={enabledDetail}
                                  onClick={() =>
                                    handleToggleBagAssign(personDetail)
                                  }
                                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                  <span className="sr-only">Use setting</span>
                                  <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                                  />
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      enabledDetail == 1
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                                    )}
                                  />
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      enabledDetail == 1
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                              </dd>
                            </div>
                            <form
                              onSubmit={UpdateRoomStatus.handleSubmit}
                              className="flex flex-col divide-y divide-gray-200 gap-2"
                            >
                              <div className="sm:flex flex items-center  sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Hotel Room Number
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <label
                                    htmlFor="checkin_status"
                                    className="block sr-only text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  ></label>
                                  <select
                                    id="checkin_status"
                                    name="checkin_status"
                                    className=" block w-[17rem] rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={UpdateRoomStatus.handleChange}
                                    value={
                                      UpdateRoomStatus.values.checkin_status
                                    }
                                    defaultValue={personDetail?.checkin_status}
                                  >
                                    <option value="Waiting for Passport">
                                      Waiting for Passport
                                    </option>
                                    <option value="Passport Approved">
                                      Passport Approved
                                    </option>
                                    <option value="Face Matched">
                                      Face Matched
                                    </option>
                                    <option value="Arrived At Destination">
                                      Arrived At Destination
                                    </option>
                                    <option value="Roommate Selected">
                                      Roommate Selected
                                    </option>
                                    <option value="Room Allocated">
                                      Room Allocated
                                    </option>
                                  </select>
                                </dd>
                                {isLoader ? (
                                  <button
                                    type="button"
                                    className="group relative ml-6 flex  w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="group relative ml-6 flex  w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </form>
                            {/* <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Checkin Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className="inline-flex rounded-full bg-green-100 px-3 py-1 text-xs font-semibold leading-5 text-green-800">
                                  {personDetail?.checkin_status}
                                </span>
                              </dd>
                            </div> */}
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Face Similarity Score
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className="inline-flex rounded-full bg-green-100 px-3 py-1 text-xs font-semibold leading-5 text-green-800">
                                  {Math.floor(
                                    personDetail?.face_similarity_score * 100
                                  ) / 100}
                                </span>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Checkin Location
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.checkin_location == null ||
                                personDetail?.checkin_location == undefined ||
                                personDetail?.checkin_location == ""
                                  ? "N/A"
                                  : personDetail?.checkin_location}
                              </dd>
                            </div>
                            {failureReason && (
                              <div className="sm:flex sm:px-6 sm:py-3">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Failure Reason
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <span className="inline-flex rounded-md bg-orange-100 px-3 py-1 text-xs font-semibold leading-5 text-orange-800">
                                    {personDetail?.failure_reason}
                                  </span>
                                </dd>
                              </div>
                            )}

                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Room Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className="inline-flex rounded-full bg-green-100 text-green-800 px-3 py-1 text-xs font-semibold leading-5">
                                  {
                                    personDetail?.event_registration
                                      ?.room_status
                                  }
                                </span>
                              </dd>
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Random Roommate
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.random_roommate === 1 ? (
                                  <CheckCircleIcon class="h-6 w-6 text-green-800" />
                                ) : (
                                  <XCircleIcon class="h-6 w-6 text-red-600" />
                                )}
                              </dd>
                            </div>
                            {/* <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Number
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {personDetail?.event_registration
                                    ?.passport_number == "" ||
                                  personDetail?.event_registration
                                    ?.passport_number == null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    personDetail?.event_registration
                                      ?.passport_number
                                  )}
                                </span>
                              </dd>
                            </div> */}
                            {/* <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Expiry
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {personDetail?.event_registration
                                    ?.passport_expiry == "" ||
                                  personDetail?.event_registration
                                    ?.passport_expiry == null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    personDetail?.event_registration
                                      ?.passport_expiry
                                  )}
                                </span>
                              </dd>
                            </div> */}

                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Payment Package
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {
                                  personDetail?.event_registration
                                    ?.payment_package
                                }
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Reset Roommate
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <button
                                  type="button"
                                  onClick={()=>setUpdatePopUp(true)}
                                  className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  Reset Roommate
                                </button>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 py-2 banner-back">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                <h1 className="text-xl font-medium text-white ">
                                  {" "}
                                  Scan Passport
                                </h1>
                              </dt>
                              {/* <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                             {personDetail?.representing_country}
                              </dd> */}
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.passport_name == null ||
                                personDetail?.passport_name == undefined ||
                                personDetail?.passport_name == ""
                                  ? "N/A"
                                  : personDetail?.passport_name}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Surname
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.passport_surname == null ||
                                personDetail?.passport_surname == undefined ||
                                personDetail?.passport_surname == ""
                                  ? "N/A"
                                  : personDetail?.passport_surname}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Gender
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.passport_gender == null ||
                                personDetail?.passport_gender == undefined ||
                                personDetail?.passport_gender == ""
                                  ? "N/A"
                                  : personDetail?.passport_gender}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Birthday
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className="mt-1  py-1 px-3 rounded-full text-sm bg-green-100 text-green-800 sm:col-span-2 sm:mt-0 ">
                                  {personDetail?.passport_birthday == null ||
                                  personDetail?.passport_birthday ==
                                    undefined ||
                                  personDetail?.passport_birthday == ""
                                    ? "N/A"
                                    : moment(
                                        personDetail?.passport_birthday
                                      ).format("DD-MM-YYYY")}
                                </span>
                              </dd>
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Number
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.passport_number == null ||
                                personDetail?.passport_number == undefined ||
                                personDetail?.passport_number == ""
                                  ? "N/A"
                                  : personDetail?.passport_number}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Expiry
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span
                                  className={classNames(
                                    passPortExpire != "green"
                                      ? "bg-red-100 text-red-800 px-2"
                                      : "bg-green-100 text-green-800 px-2",
                                    "mt-1 py-1 px-3 rounded-full text-sm text-gray-900 sm:col-span-2 sm:mt-0 "
                                  )}
                                >
                                  {personDetail?.passport_expiry == "" ||
                                  personDetail?.passport_expiry == null ? (
                                    <span className="inline-flex rounded-full  px-2 text-xs font-semibold leading-5 ">
                                      Missing
                                    </span>
                                  ) : (
                                    moment(
                                      personDetail?.passport_expiry
                                    ).format("DD-MM-YYYY")
                                  )}
                                </span>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Nationality
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.passport_nationality == null ||
                                personDetail?.passport_nationality ==
                                  undefined ||
                                personDetail?.passport_nationality == ""
                                  ? "N/A"
                                  : personDetail?.passport_nationality}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 mb-4 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Country
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.passport_country == null ||
                                personDetail?.passport_country == undefined ||
                                personDetail?.passport_country == ""
                                  ? "N/A"
                                  : personDetail?.passport_country}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={UpdatePopUp} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setUpdatePopUp}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-3 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-red-600">
                      Reset Roommate
                    </Dialog.Title>
                    <div className="mt-0.5">
                      <p className="text-sm text-gray-600">
                      This will reset the checkin and will ask the delegate to mark their destination again. Any allocated rooms and roommates will be removed as well.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 justify-end sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
               {loading==true? <><button
                    type="button"
                    className=" inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    disabled
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-3 w-full justify-center rounded-md bg-green-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500  sm:ml-3 sm:mt-0 sm:w-auto"
                    disabled
                  >
                    Loading...
                  </button></>:<> <button
                    type="button"
                    className=" inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    onClick={() => setUpdatePopUp(false)}
                    ref={cancelButtonRef}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-3 w-full justify-center rounded-md bg-green-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500  sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => ResetRoomateStatus()}
                  >
                    Yes
                  </button></>}
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
      {/* Popup for Picture */}
      <Transition.Root show={popUPImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopUPImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-2">
                  <div className="flex justify-center items-center bg-orange-200">
                    <PrivateImage
                      imageUrl={personDetail?.passport_front_upload}
                    />
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {/* <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                 
                </button> */}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopUPImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* checkin selfi */}
      <Transition.Root show={popcheckinselfieImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopcheckinselfieImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all  sm:my-8 sm:w-full sm:max-w-2xl sm:p-2">
                  <div className="flex justify-center rounded-sm items-center bg-orange-200">
                    <PrivateImage
                      imageUrl={personDetail?.checkin_selfie}
                      className="h-[35rem] w-full object-contain object-center"
                    />
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {/* <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                 
                </button> */}
                    <button
                      type="button"
                      className="mx-4 my-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopcheckinselfieImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SelfCheckinHotel;
