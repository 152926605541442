import React, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Avatar from "react-avatar-edit";
import Tooltip from "../Small Components/tooltip";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Toaster, toast } from 'sonner'
import Skeleton from "react-loading-skeleton";

import {
  CloudArrowDownIcon,
  ListBulletIcon,
  DocumentTextIcon,
  IdentificationIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
  UserPlusIcon,
  PencilIcon,
  ChevronRightIcon,
  ChevronLeftIcon
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import Tag from "../Tag/Tag";
import { useFormik } from "formik";
import IdCardAll from "../All ID Card/IdCardAll";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Done from "../Alerts/Done";
import NotFound from "../Alerts/NotFound";
import CustomCertificate from "../Certificate/CustomCertificate";
import PrivateImage from "../PrivateImage/PrivateImage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// Configrations
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

// States
const NameChngReq = () => {
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [popUPImage, setPopUPImage] = useState(false);
  const [apiFiltersNameChange, setApiFiltersNameChange] = useState("");
  const [openDraw, setOpenDraw] = useState(false);
  const { eventID } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNotFoundName,  setIsNotFoundName ] = useState(false);
  const [nameRequestUpdate, setNameRequestUpdate] = useState(false);
  const cancelButtonRef = useRef(null);

  const [dataUpdateNameChangeToggle, setDataUpdateNameChangeToggle] =
    useState(false);
  const [open, setOpen] = useState(false);
  // Api call to get Name Change Requests
  const [nameChangeData, setNameChangeData] = useState();
  const [totalRequestCount, setTotalRequestCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    const fetchEventData = async () => {
      setLoadingSkeleton(true)
      try {
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/get-name-change-requests?filters=[${apiFiltersNameChange}]`,
            config
          )
          .then((response) => {
            setTotalRequestCount(response.data.data.length);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 40);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            setNameChangeData(response.data.data);
            setLoadingSkeleton(false)
            if(response.data.data.length==[]){
              setLoadingSkeleton(true)
              toast.error('No Data Found')
              setIsNotFoundName(true);
            }
          });
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK") {
          toast.error('No Data Found')
          setLoadingSkeleton(true)
          setIsNotFoundName(true);
          
        }
      }
    };
    fetchEventData();
  }, [dataUpdateNameChangeToggle, apiFiltersNameChange]);
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };
  // Formik for Filter on Invitation
  const applyFiltersNameChange = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      setApiFiltersNameChange("");

      //  Payment Package
      if (values.status) {
        if (values.status != "None") {
          setApiFiltersNameChange(`["status","=","${values.status}"]`);
        } else {
          setApiFiltersNameChange("");
        }
      }
    },
    enableReinitialize: true,
  });
  const [signleNameChangeRequest, setSingleNameChangeRequest] = useState(null);
  const [isImageName, setIsImageName] = useState(false);
  const [isPDFName, setIsPDFName] = useState(false);
  const getSpecificNameRequest = async (data) => {
    await setSingleNameChangeRequest(data);
    if (data.legal_file) {
      if (data.legal_file.length > 1) {
        var splitFile = data.legal_file;
        var split = splitFile.split(".");
        var final = split[split.length - 1];
        if (final == "pdf") {
          setIsPDFName(true);
          setIsImageName(false);
        } else {
          console.log(final);
          setIsImageName(true);
          setIsPDFName(false);
        }
      }
    }

    setOpenDraw(true);
  };
  // Fromik for name change request
  const nameChangeRequest = useFormik({
    initialValues: {
      first_name: signleNameChangeRequest?.old_first_name,
      last_name: signleNameChangeRequest?.old_last_name,
      new_first_name: signleNameChangeRequest?.new_first_name,
      new_last_name: signleNameChangeRequest?.new_last_name,
      status: signleNameChangeRequest?.status,
    },
    onSubmit: (values) => {
      console.log(values);

      if (values.status == "Approved") {
        var updateJSONrequest = {
          status: values.status,
        };
        const updateNameStatus = async () => {
          try {
            await axios
              .put(
                "https://dash.bestdiplomats.org/api/resource/Delegate Name Revision Request/" +
                  signleNameChangeRequest.name,
                updateJSONrequest,
                config
              )
              .then((response) => {
                var updateJSONrequest = {
                  relatedUser: {
                    id: signleNameChangeRequest.delegate_profile.user.name,
                    updateData: {
                      first_name: values.new_first_name,
                      last_name: values.new_last_name,
                    },
                  },
                };
                const updateName = async () => {
                  try {
                    await axios
                      .post(
                        "https://dash.bestdiplomats.org/api/method/update-delegate",
                        updateJSONrequest,
                        config
                      )
                      .then((response) => {
                        setNameRequestUpdate(true);
                        setTimeout(() => {
                          setNameRequestUpdate(false);
                        }, 2000);
                        setDataUpdateNameChangeToggle(
                          !dataUpdateNameChangeToggle
                        );
                      });
                  } catch (e) {
                    console.log(e);
                  }
                };

                updateName();
              });
          } catch (e) {
            console.log(e);
          }
        };

        updateNameStatus();
      } else {
        var updateJSONrequest = {
          status: values.status,
        };
        const updateNameStatus = async () => {
          try {
            await axios
              .put(
                "https://dash.bestdiplomats.org/api/resource/Delegate Name Revision Request/" +
                  signleNameChangeRequest.name,
                updateJSONrequest,
                config
              )
              .then((response) => {
                setNameRequestUpdate(true);
                setTimeout(() => {
                  setNameRequestUpdate(false);
                }, 2000);
                setDataUpdateNameChangeToggle(!dataUpdateNameChangeToggle);
              });
          } catch (e) {
            console.log(e);
          }
        };

        updateNameStatus();
      }
    },
    enableReinitialize: true,
  });
  const handleChangeIfImage = () => {
    setPopUPImage(true);
  };

  return (
    <>
      <div className="mt-3">
      <Toaster position='bottom-left' richColors />
        <div className="mt-4 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
          <form onSubmit={applyFiltersNameChange.handleSubmit}>
            <div className="mt-4 flex justify-end gap-3 items-center  sm:mt-0 sm:ml-16         sm:flex-none">
            {loadingSkeleton ?<><Skeleton width={80} height={40} /> <Skeleton width={80} height={40} /></>: <>   <label htmlFor="status" className="sr-only"></label>
              <select
                id="status"
                name="status"
                className=" block w-60  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFiltersNameChange.handleChange}
                value={applyFiltersNameChange.values.status}
              >
                <option value="None">All</option>
                <option value="Approved">Approved</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
              </select>
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Apply Filter
              </button></>}
            </div>
          </form>
        </div>
      </div>
      <div className="mt-2">
        <p className="felx justify-end text-right font-semibold">
        {loadingSkeleton ?<><Skeleton width={40} height={20} /> </>: <>Requests: {totalRequestCount}</>}
        </p>
      </div>
      <div className="px-3">
      <div className="mt-3 flex flex-col max-h-screen">
        <div ref={tableRef} className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-5">
          
            { isNotFoundName? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
</svg> <span className=" text-lg text-gray-400">No Feedback Found</span> 
                </div>:   <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"><table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Delegate
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                {  loadingSkeleton? <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     
                       <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                    
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td> 
                  
                    

                     
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                  {/* All Event Registration Tab */}

                   { nameChangeData?.slice((currentPage - 1) * 40, currentPage * 40).map((person) => (
                      <tr key={person.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0"   onClick={() => {
                                  getSpecificNameRequest(person);
                                }}>
                            <PrivateImage imageUrl={ person.delegate_profile.user.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> 
                             
                            </div>
                            <div className="ml-4">
                              <div
                                onClick={() => {
                                  getSpecificNameRequest(person);
                                }}
                                className="font-medium text-gray-900 cursor-pointer"
                              >
                                {person.delegate_profile.user.first_name +
                                  " " +
                                  person.delegate_profile.user.last_name}
                              </div>
                              <div className="text-gray-500">
                                {person.delegate_profile.user.name}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span
                            onClick={() => {
                              getSpecificNameRequest(person);
                            }}
                            className={
                              person.status == "Approved"
                                ? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 cursor-pointer"
                                : person.status == "Pending"
                                ? "inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500 cursor-pointer"
                                : "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-500 cursor-pointer"
                            }
                          >
                            {person.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>}
              </table></div>}
            
          </div>
        </div>
      </div>
      </div>
      {isNotFoundName ? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 40 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (totalRequestCount % 40)}</span> of{" "}
                  <span className="font-medium">{totalRequestCount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
      {/* Drawer For Name Change Request */}
      <Transition.Root show={openDraw} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenDraw}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={nameChangeRequest.handleSubmit}
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="banner-back px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-white">
                                Review Name Change Request
                              </Dialog.Title>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-white hover:text-gray-200"
                                onClick={() => setOpenDraw(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* Profile Pic */}
                          <div className="divide-y divide-gray-200">
                            <div className="pb-2">
                              <div className="h-10 banner-back sm:h-10 lg:h-10" />
                              <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                                <div className="mt-6 sm:ml-6 sm:flex-1">
                                  <div>
                                    <div className="flex items-center">
                                      <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                        {/* {currentUser?.data?.user?.full_name} */}
                                      </h3>
                                    </div>
                                    <p className="text-sm text-gray-500">
                                      {/* {currentUser?.data?.user?.email} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* First name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                First name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.first_name}
                                disabled
                                className="block w-full rounded-md text-gray-400 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Last name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="last_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Last name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.last_name}
                                disabled
                                className="block w-full rounded-md text-gray-400 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* First name Updated*/}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="new_first_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                New First name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="new_first_name"
                                id="new_first_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.new_first_name}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Last name Updated */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="new_last_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                New Last name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="new_last_name"
                                id="new_last_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.new_last_name}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <div className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                                Legal File
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              {isPDFName && (
                                <a
                                  href={
                                    "https://dash.bestdiplomats.org" +
                                    signleNameChangeRequest?.legal_file
                                  }
                                  target="_blank"
                                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  Open{" "}
                                  <svg
                                    className="w-5 h-5 ml-1"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                    ></path>
                                  </svg>
                                </a>
                              )}
                              {isImageName && (
                                <a
                                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                                  onClick={handleChangeIfImage}
                                >
                                  Open
                                </a>
                              )}
                            </div>
                          </div>
                          {/* Status */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="status"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Status
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <select
                                type="text"
                                name="status"
                                id="status"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.status}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              >
                                <option value="Pending">Pending</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpenDraw(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex color justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Popup for Picture */}
      <Transition.Root show={popUPImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopUPImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex justify-center items-center bg-orange-200">
                    <img
                      src={
                        "https://dash.bestdiplomats.org" +
                        signleNameChangeRequest?.legal_file
                      }
                      alt=""
                    />
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {/* <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                 
                </button> */}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopUPImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NameChngReq;
