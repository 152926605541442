import React, { useEffect, useState } from 'react'

import BlankCertificate from '../../img/Custom Certificate for impact director (1).png'
import FontAbhaya from '../../AbhayaLibre-SemiBold.ttf'
import RobotoBold from '../../Roboto-Bold.ttf'
import AbhayaRegular from '../../AbhayaLibre-Regular.ttf'
import AbhayaBold from '../../AbhayaLibre-Bold.ttf'
import GoodVibrations from '../../GoodVibrations Script.ttf'
import PoppinsSemiBold from '../../Poppins-SemiBold.ttf'
import PoppinsRegular from '../../Poppins-Regular.ttf'
import QRCode from 'qrcode'
import { Document, Page, Text, View, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import moment from "moment";

const CoustomCertificateForimpactDirector = (props) => {

    if (
        (
          props.FirstName +
          props.LastName
        ).length > 25
      ) {
        
        var setFontSize = 38;
        var setFullNameTop = 360.7;
        var setLowerViewTop = 377.6;
        // var setcomplete =378.3;
      } else {
        
        var setFontSize = 44;
        var setFullNameTop = 350.0;
        var setLowerViewTop = 357.2;
      }
      if ((props?.first_line).length > 90) {
       
        var setcomplete =392.4;
      } else if ((props?.first_line).length > 25) {
        
        var setcomplete =407.3;
      } else {
        
      
        var  setcomplete = 405.5;
      }

    Font.register({
        family: "AbhayaLibreSemiBold",
        src: FontAbhaya,
      });
      Font.register({
        family: "RobotoBold",
        src: RobotoBold,
      });
      Font.register({
        family: "AbhayaRegular",
        src: AbhayaRegular,
      });
      Font.register({
        family: "AbhayaBold",
        src: AbhayaBold,
      });  Font.register({
        family: "PoppinsSemiBold",
        src: PoppinsSemiBold,
      }); Font.register({
        family: "GoodVibrations",
        src: GoodVibrations,
      }); Font.register({
        family: "PoppinsRegular",
        src: PoppinsRegular,
      });
      const styles = StyleSheet.create({
        page: {},
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        viewer: {
          width: 870,
          height: 690,
        },
        bgimages: {
          height:595,
          width:842,
          position: "absolute",
        },
        title: {
          top: 15,
          textAlign: "center",
          fontSize: 58,
          color: "white",
          fontFamily: "AbhayaLibreSemiBold",
        },
        participation: {
          top: 104.5,
          textAlign: "center",
          fontSize: 21,
          color: "#fff",
          fontFamily: "RobotoBold",
        },
        initialline: {
          letterSpacing: 1.5,
          top: 310.3,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        showcase: {
        //   top: 250,
        marginTop:1,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "PoppinsRegular",
          marginHorizontal: 140,
        },
        complete: {
          top: 260,
          textAlign: "center",
          fontSize: 10,
          color: "#000000",
          fontFamily: "PoppinsRegular",
        },
        completeDate: {
          top: 260,
          textAlign: "center",
          fontSize: 10,
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        completeLocation: {
        //   top: 260,
        // marginTop:2,
          textAlign: "center",
          fontSize: 10,
          color: "#000000",
          fontFamily: "PoppinsRegular",
        },
        fullname: {
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        headNameRight: {
          position:'absolute',
          top: 375,
          left:72,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
        },
        headNameLeft: {
          position:'absolute',
          left:650,
          top: 375,
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
          marginLeft: 18,
        },
        headPositionRight: {
          position:'absolute',
          top: 395,
          left:78,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginRight: 45,
        },
        headPositionLeft: {
          position:'absolute',
          left:657,
          top: 395,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginLeft: 24,
        },
        QRImage: {
          top:493,
          left: 390,
          position: "absolute",
          width: 75,
        },
      });

    // States
   
    const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#002060", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  generateQR(props.qrText);
    
  return (
  <Document>
    <Page size={[842,595]} >
    <Image src={BlankCertificate} style={styles.bgimages}></Image>
    {/* <Text style={styles.title}>CERTIFICATE</Text> */}
    <Text style={styles.participation}>{props?.type}</Text>
    {/* <Text style={styles.initialline}>THIS CERTIFICATE IS PRESENTED TO</Text> */}
    <Text  style={{
                          top: setFullNameTop,
                          fontSize: setFontSize,
                          textAlign: "center",
                           textTransform: "capitalize",

                          color: "#002060",
                          fontFamily: "GoodVibrations",
                        }}>{""}{""+(props.FirstName)?.toLowerCase()+" "+(props.LastName)?.toLowerCase()}</Text>
    <View  style={{ top: setLowerViewTop }}>
    <Text style={styles.showcase}>{props.first_line+" "+props?.country}</Text>
   </View>
    <Text style={{ top:setcomplete,
                            left:96.5,
                            textAlign: "start",
                            fontSize: 13,
                            color: "#000000",
                            fontFamily: "PoppinsRegular",}}>
            {moment(props?.end_date).format(
              "D MMMM, YYYY"
            ) }
          </Text>
    <Image source={qrUriImageFile} style={styles.QRImage}></Image>

    </Page>
  </Document>
  )
}

export default CoustomCertificateForimpactDirector