import React, { useRef } from 'react'
import { useEffect,useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useParams} from "react-router-dom";
import Papa from "papaparse";
import Skeleton from "react-loading-skeleton";
import { Toaster, toast } from "sonner";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloudArrowDownIcon,
  DocumentArrowDownIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

function Invitationevent() {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
    const { eventID } = useParams();
    const [eventinvitation, setEventInvitation] = useState(false);
    const [open, setOpen] = useState(false);
    const [isDeleteID, setDeleteID] = useState(null);
  const [isDelete, setDelete] = useState(false);
    const [UpdatePayment, setUpdatePayment] = useState(false);
    const [invitation, setInvitation] = useState(null);
    const [invitationCount, setInvitationCount] = useState(0);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [isNotFoundName, setIsNotFoundName] = useState(false);
    const [inviteFilter, setInviteFilter] = useState(
      `["event","=","${eventID}"]`
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumbers, setpageNumber] = useState([]);
    const tableRef = useRef(null);
    useEffect(() => {
      const geteventdata = async () => {
        setLoadingSkeleton(true)
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/resource/Delegate Invitations?fields=["*"]&filters=[${inviteFilter}]&limit_page_length=999`,config
          )
          .then((res) => {
            setInvitationCount(res.data.data.length);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(res.data.data.length / 25);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            setInvitation(res.data.data);
            setLoadingSkeleton(false)
            if(res.data.data.length==[]){
              toast.error('No Data Found')
              setLoadingSkeleton(true)
              setIsNotFoundName(true);
            }
            // console.log(">>>>> inv",res.data.data)
          })
          .catch((e) => {
            if (e.code == "ERR_NETWORK") {
              
              toast.error('No Data Found')
              setLoadingSkeleton(true)
              setIsNotFoundName(true);
            }
            console.log("eventinvitation errrrr", e);
          });
      };
      geteventdata();
    }, [inviteFilter, UpdatePayment]);
    const scrollToTop = () => {
      tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleClick = (number) => {
      setCurrentPage(number);
      scroll();
      scrollToTop()
    };
    const scroll = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Formik for Filter on Invitation
    const applyFiltersInvitation = useFormik({
      initialValues: {},
      onSubmit: (values) => {
        setInviteFilter(`["event","=","${eventID}"]`);
  
        // console.log(values);
        //  Payment Package
        if (values.status) {
          if (values.status != "None") {
            setInviteFilter((prevState) => [
              prevState + `,["invitation_status","=","${values.status}"]`,
            ]);
          } else {
            setInviteFilter(`["event","=","${eventID}"]`);
          }
        }
      },
      enableReinitialize: true,
    });
  
    const handleDownload = () => {
      const newData = invitation?.map((item) => ({
       
        Name: item?.delegate_name,
        Invitation_Status : item?.invitation_status,
        Representing_Country: item?.representing_country == "" || item?.representing_country == null ? "N/A" :item?.representing_country,
        Payment_Package: item?.payment_package ,
    
          }));
    
      const csv = Papa.unparse(newData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "my-file.csv");
      document.body.appendChild(link);
      link.click();
    };
    const handlePaginationClick = (event) => {
      if (event == "Previous") {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scroll();
          scrollToTop()
        }
      } else if (event == "Next") {
        if (currentPage < pageNumbers.length) {
          setCurrentPage(currentPage + 1);
          scroll();
          scrollToTop()
        }
      }
    };
    const handleChangepaymentPackage=()=>{
      setOpen(true)
      
    }
    const paymentUpdate = useFormik({
      initialValues: {
        payment_package: eventinvitation?.payment_package
        ,
   
      },
      onSubmit: (values) => {
        var paymentPackeg = {
          payment_package: values.payment_package,
         
        }
        const fetchcategory = async () => {
          try {
            await axios
              .put(
                `${window.$domain}/api/resource/Delegate Invitations/${eventinvitation?.name}`,
                paymentPackeg,
                config
              )
              .then((res) => {
                console.log("updatae",res);
                toast.success('Updated successfully')
                setUpdatePayment(!UpdatePayment)
              });
          } catch (e) {
            console.log(e);
            toast.error('Failed to Update')
          }
        };
  
        fetchcategory();
      },
  
      enableReinitialize: true,
    });
    const handleDeleteInvitation=(person)=>{
      setDelete(true);
      setDeleteID(person);
    }
      // Formik for delete
  const InvitationDelete = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const result = await axios.delete(
          `${window.$domain}/api/resource/Delegate Invitations/${isDeleteID}`,
          config
        );
        // console.log(result);
        toast.success("Deleted successfully");
        setUpdatePayment(!UpdatePayment)
      } catch (e) {
        console.error(e);
        toast.error("Failed to delete");
      }
    },
    enableReinitialize: true,
  });
  return (
  
  <>
  
    <div className="px-2 divide-y mb-3  sm:px-1 lg:px-4">
      <div className="mt-3">
        <div className="mt-4 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
          <form onSubmit={applyFiltersInvitation.handleSubmit}>
            <div className="mt-4 flex justify-end gap-3 items-center  sm:mt-0 sm:ml-1 sm:flex-none">
            {loadingSkeleton ?<> <Skeleton width={70} height={40} /> <Skeleton width={70} height={40} /> <Skeleton width={70} height={40} /> </>:<>
            <a
                  href={`${window.$FWDurl}/bulkCertificateParticipants/${eventID}`}
                  target="_blank"
                  className=" w-fit inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Download Certificates{" "}
                  <CloudArrowDownIcon className="h-5 w-5" />
                </a> <a
                  href={`${window.$FWDurl}/IDCardParticipants/${eventID}`}
                  target="_blank"
                  className=" w-fit inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Download ID Cards{" "}
                  <CloudArrowDownIcon className="h-5 w-5" />
                </a>
            <button
          className="inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => handleDownload()}
        >
          Download CSV
        </button>
              <label
                htmlFor="status"
                className="sr-only"></label>
              <select
                id="status"
                name="status"
                className=" block w-60  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFiltersInvitation.handleChange}
                value={applyFiltersInvitation.values.status}
              >
                <option value="None">All</option>
                <option value="Accepted">Accepted</option>
                <option value="Pending">Pending</option>
              </select>
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Apply Filter
              </button> </> }
            </div>
          </form>
        </div>
      </div>
      <div className="mt-1 py-1">
      <p className="felx justify-end text-right font-semibold">
      {loadingSkeleton ? <Skeleton width={40} height={10} /> : <>Invitations: {invitationCount}</>}
        </p>
      </div>
      <div className="mt-1 flex flex-col max-h-screen">
        <div ref={tableRef} className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-4 lg:px-4">
            
            { isNotFoundName? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
 </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>: <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">  <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                    >
                      Name & Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Payment Package
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th> <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Certificate
                    </th> <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      ID Card
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                {loadingSkeleton?<tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                    
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                  {invitation?.slice((currentPage - 1) * 25, currentPage * 25).map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-4">
                        <div className="flex items-center">
                          {/* <div className="h-10 w-10 flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={person.image}
                              alt=""
                            />
                          </div> */}
                          <div className="">
                            <div className="font-medium text-gray-900">
                              {person.delegate_name}
                            </div>
                            <div className="text-gray-500">
                            {localStorage.role=="Operations Team"? null: person.invitation_email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {person.payment_package}
                        </div>
                        <div className="text-gray-500">
                          {""}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span
                          className={
                            person.invitation_status ==
                            "Accepted"
                              ? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                              : "inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500 "
                          }
                        >
                          {person.invitation_status}
                        </span>
                      </td>
                       <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        <a
                                    href={
                                      window.$FWDurl +
                                      "/CertificateParticipent/" +
                                      eventID +
                                      "/" +
                                      person?.name
                                    }
                                    target="_blank"
                                    className=""
                                  >
                                    <CloudArrowDownIcon className="w-6 h-6 ml-2" />
                        </a>
                      </td> 
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        <a
                                    href={
                                      window.$FWDurl +
                                      "/IdCardParticipent/" +
                                      eventID +
                                      "/" +
                                      person?.invitation_email
                                    }
                                    target="_blank"
                                    className=""
                                  >
                                    <DocumentArrowDownIcon className="w-6 h-6 ml-2" />
                        </a>
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <div className="flex gap-2">
                                
                                <PencilIcon className="h-6 w-6 flex justify-center items-center text-gray-500 cursor-pointer" onClick={() =>{
                                    handleChangepaymentPackage(person)
                                    setEventInvitation(person)
                                }}/>
                                  
                                 

                                <TrashIcon className="h-6 w-6 flex justify-center items-center text-red-500 cursor-pointer" onClick={()=>handleDeleteInvitation(person.name)}/>
                                  </div>
                      </td>
                    </tr>
                  ))}
                </tbody>}
              </table>  </div>}
          
          </div>
        </div>
      </div>
    </div>

   {isNotFoundName? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 25 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (invitationCount % 25)}</span> of{" "}
                  <span className="font-medium">{invitationCount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}



          <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-96 flex-col  bg-white shadow-xl"
                      onSubmit={paymentUpdate.handleSubmit}
                    >
                      <div className="flex h-96 flex-col  bg-white shadow-xl">
                        <div className="px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Event Invitations
                            </Dialog.Title>

                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Main */}
                        <div className="divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="h-14  sm:h-14 lg:h-14 bg-blue-500" />
                            
                          </div>

                          <div className="px-4 py-5 sm:px-0 sm:py-0">
                            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                           

                              
                              
                            
                             

                             
                              <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Invitation Status

                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                               
                                <span
                          className={
                            eventinvitation?.invitation_status ==
                            "Accepted"
                              ? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                              : "inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500 "
                          }
                        >
                          {eventinvitation?.invitation_status}
                        </span>
                                </dd>
                              </div>
                              <div className="sm:flex lg:items-center sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Payment Package
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <label
                                    htmlFor="payment_package"
                                    className=" sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="payment_package"
                                    id="payment_package"
                                    onChange={paymentUpdate.handleChange}
                                    value={paymentUpdate.values.payment_package}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="Accommodation">Accommodation</option>
                                    <option value="Non-Accommodation">Non-Accommodation</option>
                                    <option value="Accommodation (First Payment Pending)">Accommodation (First Payment Pending)</option>
                                    <option value="Non-Accommodation (First Payment Pending)">Non-Accommodation (First Payment Pending)</option>
                                    <option value="Accommodation (Second Payment Pending)">Accommodation (Second Payment Pending)</option>
                                    <option value="Non-Accommodation (Second Payment Pending)">Non-Accommodation (Second Payment Pending)</option>
                                    <option value="Pay on Arrival">Pay on Arrival</option>
                                    <option value="Full Experience">Full Experience</option>
                                    <option value="Full Experience (Second Payment Pending)">Full Experience (Second Payment Pending)</option>
                                    
                                  </select>
                                </dd>
                              </div>
                             
                              

                              <div className="flex-shrink-0  flex justify-end border-t border-gray-200 px-3 py-3 sm:px-9">
                                
                                  <button
                                    type="submit"
                                    className="group  flex w-28 mt-4 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Delete Popup */}
       <Transition.Root show={isDelete} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDelete}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        Are you sure want to DELETE?
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={InvitationDelete.handleSubmit}
                    className="mt-5 sm:mt-6"
                  >
                    <button
                      type="submit"
                      className="inline-flex w-full text-lg justify-center rounded-md bg-red-400 px-3 py-2 font-semibold text-white  hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      onClick={() => setDelete(false)}
                    >
                      Delete
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
   </>
  )
}

export default Invitationevent