import React, { useState } from 'react';
import CSVReader from 'react-csv-reader';


const SendInvitationEvent = () => {
    const jsonToCsv = (json) => {
        const flattenObject = (obj, prefix = '') => {
          return Object.keys(obj).reduce((acc, key) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[key] === 'object' && obj[key] !== null) {
              Object.assign(acc, flattenObject(obj[key], pre + key));
            } else {
              // Check if the value contains a newline character
              if (typeof obj[key] === 'string' && obj[key].includes('\n')) {
                // Split the value by newline character and create separate columns
                const splitValues = obj[key].split('\n');
                splitValues.forEach((value, index) => {
                  acc[pre + key + (index + 1)] = value.trim();
                });
              } else {
                acc[pre + key] = obj[key];
              }
            }
            return acc;
          }, {});
        };
      
        const flattenData = json.map((item) => flattenObject(item));
      
        const headers = Array.from(
          new Set(flattenData.flatMap((item) => Object.keys(item)))
        );
      
        const csv = [
          headers.join(','),
          ...flattenData.map((item) =>
            headers.map((header) => item[header] || '').join(',')
          ),
        ].join('\n');
      
        return csv;
      };
      
      
      const jsonData = {  
        "hero_section":{
            "heading":"Latest Collections",
            "description":"There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form."
         },
        "shop_by_color":"SHOP BY COLOR",
        "shop_womens":{
            "title":"SHOP WOMEN'S",
            "description":"At Labcote we celebrate the brilliance and contributions of women in the scientific community. Our commitment to excellence extends to a curated selection of laboratory equipment, supplies and solutions designed with the needs of pioneering women in mind.",
            "shop_button":"Shop Now"
        },
        "shop_men_trending":"SHOP MEN'S TRENDING NOW",
        "shop_mens":{
            "title":"SHOP MEN'S",
            "description":"At Labcote we celebrate the brilliance and contributions of women in the scientific community. Our commitment to excellence extends to a curated selection of laboratory equipment supplies and solutions designed with the needs of pioneering women in mind",
            "shop_button":"Shop Now"
        },
        "our-best-collection":"OUR BEST COLLECTION",
        "subscribe-take":{
        "title":"Subscribe Take 15% OFF",
        "discription":"Subscribe to take 15% OFF your first purchase and stay in the know on exclusive new colors, styles and promotions."
        },
        "Incentives-section":{
        "delivery":{
        "title":"Delivery.",
        "description":"It's not actually free we just price it into the products. Someone's paying for it, and it's not us."
        } ,
        "payment":{
        "title":"Online Payment.",
        "description":"If it breaks in the first 10 years we'll replace it. After that you're on your own though."
        },
        "return":{
        "title":"Easy Return.",
        "description":"If you don't like it, trade it to one of your friends for something of theirs. Don't send it here though."
        }
        },
        "men-page":{
        "title":"Shop Men's",
        "description":"Discover a world of innovation and excellence at Labcot, where cutting-edge technology meets unrivaled quality. We take pride in offering a curated selection of laboratory equipment, supplies, and solutions to meet the diverse needs of scientific enthusiasts, professionals, and researchers."
        }, 
        "women-page":{
        "title":"Shop Women's",
        "description":"At Labcote, we celebrate the brilliance and contributions of women in the scientific community Our commitment to excellence extends to a curated selection of laboratory equipment, supplies, and solutions designed with the needs of pioneering women in mind."
        },
        "generic-product-page":{
            "title":"Scrub Top",
            "description":"At Labcote we celebrate the brilliance and contributions of women in the scientific community Our commitment to excellence extends to a curated selection of laboratory equipment, supplies, and solutions designed with the needs of pioneering women in mind."
        
        }, 
        "shop-by-page":{
            "title":"Caribbean Blue Bahama",
            "description":"Code caribbean blue-bahama in a good way"
        
        },
    
    
        "home": "Home",
        "men": "Men",
        "women": "Women",
        "outlet": "Outlet 40% To 70%",
        "stethoscope": "Stethoscope & Surgical Caps",
        "accessorie": "Accessories",
        "header-home-page":"Latest Collections",
        "header-home-para":"There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form",
        "button":"Shop Now",
        "shop-by-color":"Shop By Color",
        "womens":{
            "trending-products":"Trending Products",
            "title":"Shop Women's",
            "description":"At Labcote we celebrate the brilliance and contributions of women in the scientific community Our commitment to excellence extends to a curated selection of laboratory equipment supplies and solutions designed with the needs of pioneering women in mind"
        },
        "mens":{
            "trending-products":"Trending Products",
            "title":"Shop Men's",
            "description":"Discover a world of innovation and excellence at Labcot where cutting-edge technology meets unrivaled quality. We take pride in offering a curated selection of laboratory equipment supplies and solutions to meet the diverse needs of scientific enthusiasts professionals and researchers"
        },
        
    "trending-mens":{
    
        "shop-mens-trending":"Shop Men's Trending Now",
        "t-shirt":"T-shirt for Men’s"
    },
    "total-price":{
        "top-banner":"FREE SHIPPING FOR SR 50+ ORDERS AND FREE RETURNS",
        "sign":"SAR",
        "t-price":"Total Price"
    },
     "shoppingcart":"Shopping cart"
       
        };
      
      const csvDataz = jsonToCsv([jsonData]);
    const people = [
        { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
        // More people...
      ]
    const [csvData, setCsvData] = useState([]);

    const handleOnFileLoaded = (data, fileInfo) => {
      // 'data' is an array of arrays representing rows and columns of the CSV file
      console.log("JSON.stringify(csvData, null, 2)",JSON.stringify(data, null, 2));
      setCsvData(data);
    };
  
    const handleOnError = (err, file, inputElem, reason) => {
      console.log(err);
    };


    const downloadCsv = () => {
        const blob = new Blob([csvDataz], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };


    
  return (
    <div>
          <button onClick={downloadCsv}>Download CSV</button>
      <CSVReader
        onFileLoaded={handleOnFileLoaded}
        onError={handleOnError}
        parserOptions={{
          header: true,
          skipEmptyLines: true
        }}
      />
 
       <div className="px-2 sm:px-1 lg:px-1 ">
      <div className="sm:flex sm:items-center">
        {/* <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title, email and role.
          </p>
        </div> */}
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root ">
        <div className="-mx-4 -my-2 overflow-x-auto max-h-screen  ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-2 lg:px-2">
            <table className="min-w-full divide-y divide-gray-300 border rounded-md  border-gray-300">
              <thead>
                <tr className='bg-gray-100 border  border-gray-300 rounded-md'>
                {csvData.length > 0 &&
                  Object.keys(csvData[0]).map((key, index) => (
                    <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  {key}</th>
                  ))}
              
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
              {csvData.map((row, rowIndex) => (
                  <tr key={rowIndex} >
                       {Object.values(row).map((cell, cellIndex) => (
                    <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-2">
                     {cell}</td>
                  ))}
                  
                  
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> 
    </div>
  )
}

export default SendInvitationEvent