import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import JSZip from "jszip";
import IdCardAll from "../../All ID Card/IdCardAll";
import QRCode from "qrcode";
import { useParams } from "react-router-dom";
import LoadingIcons from "react-loading-icons";

const IDCardBulk = () => {
  const [isDone, setIsDone] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const { eventid } = useParams();

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const zip = new JSZip();
  const folder = zip.folder("files");
  const generateQR = async (text) => {
    try {
      return await QRCode.toDataURL(text, {
        color: {
          dark: "#4c8dcb", // Blue dots
          light: "#0000", // Transparent background
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };
  const generatePdfDocument = async (documentData, firstname, lastname) => {
    // console.log("Done Doing<<<<");
    const blob = await pdf(documentData).toBlob();
    folder.file(firstname + " " + lastname + ".pdf", blob);

    // saveAs(blob,  firstname+" "+lastname);
  };

  // Call to get all Event Registerations
  useEffect(() => {
    const fetchEventRegisteration = async () => {
      try {
        
        const result = await axios
          .get(
            `${window.$domain}/api/resource/Guest Registration?fields=["*"]&filters=[["event","=","${eventid}"]]`,
            config
          )
          .then((responseReg) => {
            const fetchEventData = async () => {
              try {
                const result = await axios
                  .get(
                    "https://dash.bestdiplomats.org/api/resource/Events/" +
                    responseReg.data.data[0].event
                  )
                  .then((responseEvent) => {
                    const forLoop = async () => {
                      for(
                        let index = 0;
                        index <=responseReg.data.data.length;
                        index++
                      ) 
                      {
                        // console.log("Doing>>", index);
                        setPercentage(Math.floor(index/responseReg.data.data.length*100))
                        const getImage = async () => {
                          var  url = responseReg.data.data[index]?.image
                          var new_url = url?.replace("/private", "")
                          try {
                            const result = await axios
                              .get(
                                `https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url=${new_url}`,
                                {
                                  headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                  },
                                  responseType: "blob",
                                  timeout: 90000,
                                }
                              )
                              .then((response) => {
                                
                                var reader = new FileReader();
                                reader.readAsDataURL(response.data);
                                reader.onloadend = function () {
                                  var base64data = reader.result;
                                  generatePdfDocument(
                                    <IdCardAll
                                      profileImage={base64data}
                                      payment_package="Black"
                                      firstName={responseReg.data.data[index]?.first_name}
                                      lastName={responseReg.data.data[index]?.last_name}
                                      firstLine={responseReg.data.data[index].first_line}
                                      secondLine={responseReg.data.data[index].second_line}
                                      qrText={responseReg.data.data[index]?.name+"-g"}
                                      eventName={responseEvent.data.data.title}
                                    />,
                                    responseReg.data.data[index]?.first_name,
                                    responseReg.data.data[index]?.last_name
                                  );
                                };
                              });
                          } catch (e) {
                          
                            console.log(e);
                          }
                        };
                        getImage();
                        await sleep(4000);
                      }
                      zip
                        .generateAsync({ type: "blob" })
                        .then((blob) => saveAs(blob, "All ID Cards"));
                      setIsDone(true);
                    };
                    forLoop();

                    
                  });
              } catch (e) {
                console.log(e);
              }
            };
            fetchEventData();
          });
        
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventRegisteration();
  }, []);
  return (
    <div>
      {isDone ? (
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-green-700 my-4  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2">
          Done Downloading
        </span>
      ) : (<>
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <LoadingIcons.SpinningCircles className="w-7 h-7"/>
        </span>
        <div className="flex justify-center">
        <span>This may take a few minutes. Your file will be download automatically.</span>
        </div>
        </>
      )}
      <div className="flex justify-center mt-2">
      <div className="w-96 bg-gray-200 rounded-full dark:bg-gray-700 ">
    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{'width':percentage+"%"}}> {percentage}%</div>
  </div>
  </div>
    </div>
  );
};

export default IDCardBulk;
