import React, { useRef } from 'react'

import { useEffect,useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useParams} from "react-router-dom";
import Papa from "papaparse";
import { Fragment } from "react";
import { Toaster, toast } from 'sonner'
import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, CheckIcon, CheckBadgeIcon, CheckCircleIcon, MinusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import ReactPlayer from 'react-player';
import Done from '../Alerts/Done';
import Skeleton from "react-loading-skeleton";
import PrivateImage from '../PrivateImage/PrivateImage';
import moment from 'moment/moment';



var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ApplyForEvents =()=>{

  const[submitDone , setSubmitDone]=useState(false)
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [open, setOpen] = useState(false);
  const [onRejectedStatusReview, setOnRejectedStatusReview ] = useState(false);
  const cancelButtonRef = useRef(null);
  const {eventID} = useParams();
  const [isNotFound, setIsNotFound] = useState(false);
  const [culturePrfomis, setculturePrfomis] = useState(null);
  const [culturePrfomisCount, setCulturePrfomisCount] = useState(0);
  const[personDetail, setPersonDetail]=useState("")
  const [inviteFilter, setInviteFilter] = useState(`["event","=","${eventID}"]`);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  // console.log("eventid",eventID);
  
    useEffect(() => {
      // setLoadingSkeleton(true)
      const  getculturePrfomistdata = async () => {
        const result = await axios
          .get(
            `${window.$domain}/api/method/get-registration-requests-ops?filters=[${inviteFilter}]`,config
          )
          .then((res) => {
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(res.data.data.length / 30);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            setCulturePrfomisCount(res.data.data.length);
            setculturePrfomis(res.data.data);
            setLoadingSkeleton(false);
            setIsNotFound(false);
            if(res.data.data.length==0){
              setIsNotFound(true);
              setLoadingSkeleton(false)
              toast.error('No Data Found')
            }
            console.log(">>>>",res.data.data);
           
          })
          .catch((e) => {
            console.log(e.response);
            
            if (e?.response?.status== 404) {
              setIsNotFound(true);
              setLoadingSkeleton(true)
              toast.error('No Data Found')
            }
          });
      };
      getculturePrfomistdata();
    }, [submitDone , inviteFilter]);
    const scrollToTop = () => {
      tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleClick = (number) => {
      setCurrentPage(number);
      scroll();
      scrollToTop()
    };
    const scroll = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    const handlePaginationClick = (event) => {
      if (event == "Previous") {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scroll();
          scrollToTop()
        }
      } else if (event == "Next") {
        if (currentPage < pageNumbers.length) {
          setCurrentPage(currentPage + 1);
          scroll();
          scrollToTop()
        }
      }
    }; 
    // Formik for Filter on Invitation
    const applyFiltersInvitation = useFormik({
      initialValues: {},
      onSubmit: (values) => {
      setInviteFilter(`["event","=","${eventID}"]`)
        //  Payment Package
        if (values.registration_status) {
          if (values.registration_status != "None") {
            console.log(values.registration_status)
            // setInviteFilter((prevState)=>{[prevState+`[["approval_status","=","${values.status}"]]`]});
            setInviteFilter((prevState) => [
              prevState +
                `,["registration_status","=","${values.registration_status}"]`,
            ]);
          } else {
            setInviteFilter(`["event","=","${eventID}"]`);
          }
        }
      },
      enableReinitialize: true,
    });
 
    const Handleviewdetail=(person)=>{
      setPersonDetail(person)
      
      // console.log(person)
      setOpen(true)
    }

    const RejectedStatusReview=(value)=>{
     if(value=="Rejected"||value=="Appealed"||value=="Initial Reject"){
      setOnRejectedStatusReview(true)
     }else
     setOnRejectedStatusReview(false)
    }

    const [isSuccess,setIsSuccess]= useState(false);
    const formik = useFormik({
      initialValues: {
        registration_status:personDetail?.registration_request?.registration_status,
        rejection_comment:personDetail?.registration_request?.rejection_comment
      },
      onSubmit: values => {
        
        var jsontoPass={
          registration_status:values.registration_status,
          rejection_comment:values.rejection_comment
        }
        const fetchAmbData = async (jsontoPass) => {
          
          try {
            await axios
              .put(`${window.$domain}/api/resource/Registration Requests/${personDetail?.registration_request?.name}`,jsontoPass ,config).then((res)=>{
                // console.log(res.data.data)
                setIsSuccess(true)
                setSubmitDone(!submitDone)
                 toast.success("Profile Updated Successfully")
                setTimeout(() => {
                 
                  setIsSuccess(false)
                }, 2000);
              })
              
          } catch (e) {
            console.log(e);
            toast.error("Try Again")
          }
        };
        fetchAmbData(jsontoPass);
        
        
      },
  
      enableReinitialize: true,
    });
    const [popUPImage, setPopUPImage] = useState(false);
    const [currPersonLink, setCurrPersonLink] = useState(false);
    const handleChangeIfImage = (link) => {
      setCurrPersonLink(link);
      setPopUPImage(true);
    };
    // const handleDownload = () => {
    //   const newData = culturePrfomis?.map((item) => ({
       
    //     Name: item?.delegate_profile?.user?.first_name||item?.delegate_profile?.user?.last_name?`${item?.delegate_profile?.user?.first_name} ${item?.delegate_profile?.user?.last_name}`:" ",

    //     Performance_Type: item?.performance_type,
    //     Approval_Status: item?.approval_status ,
    //     Nationality: item?.delegate_profile?.nationality,
    //     Music_Link: item?.music_link==""||item?.music_link==null?"N/A":item?.music_link ,
    //     Performance_Description: item?.performance_description,
       
    //       }));
    
    //   const csv = Papa.unparse(newData);
    //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    //   const link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.setAttribute("download", "my-file.csv");
    //   document.body.appendChild(link);
    //   link.click();
    // };
  
    
  return (
    <>
        <div className="mt-3">
        <Toaster position='bottom-left' richColors />
     
        <div className="mt-4 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
        {/* {loadingSkeleton ?<><Skeleton width={80} height={40} /> </>: <> <button
          className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => handleDownload()}
        >
          Download CSV
        </button></>} */}
          <form onSubmit={applyFiltersInvitation.handleSubmit}>
            <div className="mt-4 flex justify-end gap-3 items-center  sm:mt-0 sm:ml-5 sm:flex-none">
          
            {loadingSkeleton ?<><Skeleton width={80} height={40} /> <Skeleton width={80} height={40} /> <Skeleton width={80} height={40} />  </>: <>  <label
                htmlFor="registration_status"
                className="sr-only"></label>
              <select
                id="registration_status"
                name="registration_status"
                className=" block w-60  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFiltersInvitation.handleChange}
                value={applyFiltersInvitation.values.registration_status}
              >
                 <option value="None">All</option>
                 <option value="Approved">Approved</option>
                 <option value="In Review">In Review</option>
                 <option value="Rejected">Rejected</option>
                 <option value="Initial Reject">Initial Reject</option>
                 <option value="Appealed">Appealed</option>
              </select>
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Apply Filter
              </button></>}
            </div>
          </form>
        </div>
      
      </div>
      <div className="mt-2 ">
        <p className="felx  justify-end text-right font-semibold">
        {loadingSkeleton ?<><Skeleton width={40} height={20} /> </>: <>  Requests: {culturePrfomisCount} </>}
        </p>
      </div>
    <div className=" pt-2 px-2 divide-y  pl-6 ">
     <div  className=" flex flex-col max-h-screen">
        <div ref={tableRef} className="-my-2 -mx-4 scrollbar-div-color overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-4 lg:px-4">
        
            {isNotFound ?<div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
 </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>:     <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"><table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 whitespace-nowrap text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name & Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left whitespace-nowrap text-sm font-semibold text-gray-900"
                    >
                      Payment Package
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left whitespace-nowrap text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th> 
                     <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900"
                    >
                      Pagelan Name
                    </th>    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900"
                    >
                      Accommodation Pricing
                    </th>                  
                       <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900"
                    >
                     Non Accommodation Pricing
                    </th>                 
                        <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900"
                    >
                      Full Experience Pricing
                    </th>
                        <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900"
                    >
                     Valid Till
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                {  loadingSkeleton? <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                       
                       <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                   
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td> <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                    

                     
                   </tr>
                 
               </tbody>:  <tbody className="divide-y divide-gray-200 bg-white">
                  {culturePrfomis?.slice((currentPage - 1) * 30, currentPage * 30).map((person) => (
                    <tr key={person.registration_request.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0" onClick={() => {
                                Handleviewdetail(person);
                                if (person?.registration_request?.registration_status=="Rejected"||person?.registration_request?.registration_status=="Initial Reject"||person?.registration_request?.registration_status=="Appealed") {
                                  setOnRejectedStatusReview(true)
                                }
                              }}>
                          <PrivateImage imageUrl={person.delegate_profile.user.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> 
                           
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900 cursor-pointer " onClick={() => {
                                Handleviewdetail(person);
                                if (person?.registration_request?.registration_status=="Rejected"||person?.registration_request?.registration_status=="Initial Reject"||person?.registration_request?.registration_status=="Appealed") {
                                  setOnRejectedStatusReview(true)
                                }
                              }}>
                              {person?.delegate_profile?.user?.first_name +" " + person?.delegate_profile?.user?.last_name}
                            </div>
                            <div className="text-gray-500">
                              {person?.delegate_profile.user?.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {person?.registration_request?.payment_package}
                        </div>
                        <div className="text-gray-500">
                          {""}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span
                          className={
                            person?.registration_request?.registration_status ==
                            "Approved"
                              ? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                              : person?.registration_request?.registration_status=="Pending" ? "inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500 " : "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-500"
                          }
                        >
                          {person?.registration_request?.registration_status}
                        </span>
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                     {person?.registration_request?.applied_pricing_plan==null?"N/A":person?.registration_request?.applied_pricing_plan?.plan_name}
                      </td>  
                       <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                     {person?.registration_request?.applied_pricing_plan==null?"N/A":person?.registration_request?.applied_pricing_plan?.accommodation_pricing}
                      </td> 
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                     {person?.registration_request?.applied_pricing_plan==null?"N/A":person?.registration_request?.applied_pricing_plan?.non_accommodation_pricing}
                      </td> 
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                     {person?.registration_request?.applied_pricing_plan==null?"N/A":person?.registration_request?.applied_pricing_plan?.full_experience_pricing}
                      </td> 
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                     <span className= "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{person?.registration_request?.applied_pricing_plan==null?"N/A":moment(person?.registration_request?.applied_pricing_plan?.valid_till).format('MM-DD-YYYY')}</span>  
                      </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {/* <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                          <span className="sr-only">
                            , {person.name}
                          </span>
                        </a> */}
                      </td>
                    </tr>
                  ))}
                </tbody>}
              </table></div>}
            
          </div>
        </div>
      </div>
    </div>
    {isNotFound? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 30 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (culturePrfomisCount % 30)}</span> of{" "}
                  <span className="font-medium">{culturePrfomisCount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
      {/* Drawer for details */}
 
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{
          setOpen(!open)
          setOnRejectedStatusReview(false)
          }}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0  overflow-hidden">
            <div className="absolute inset-0  overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full scrollbar-div-color flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-4 sm:px-4">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Profile
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 banner-back sm:h-20 lg:h-16" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-md ">
                                <PrivateImage imageUrl={personDetail?.delegate_profile?.user?.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-24 lg:w-24"}/> 
                                  
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 sm:ml-4 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-xl">
                                    {personDetail?.delegate_profile?.user?.first_name}{" "}
                                    {personDetail?.delegate_profile?.user?.last_name}
                                  </h3>
                                 
                                </div>
                                <p className="text-sm text-gray-500">
                                  {personDetail?.delegate_profile?.user?.email}
                                </p>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-4 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                         

                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                               Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <span className={ personDetail?.registration_request?.registration_status==
                                "Approved"? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                                :(personDetail?.registration_request?.registration_status=="Rejected")?("inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-500"):("inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500")}>
                                {personDetail?.registration_request?.registration_status}
                             </span>
                              </dd>
                            </div>
                          { personDetail?.registration_request?.registration_status=="Approved"?
                          <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          Approval Date
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                            <span className={ personDetail?.registration_request?.registration_status==
                            "Approved"? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800":("inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500")}>
                            
                            {moment(personDetail?.registration_request?.registration_approval_date).format('MM-DD-YYYY')}
                         </span>
                          </dd>
                        </div>:""}  
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment

                              </dt>
                              <dd className="mt-1 text-sm flex items-center gap-8 sm:col-span-2 sm:mt-0 sm:ml-6">
                            {personDetail?.registration_request?.first_installment_payment_reference==null||personDetail?.registration_request?.first_installment_payment_reference==undefined||personDetail?.registration_request?.first_installment_payment_reference==""?
                             <div  className='flex items-center flex-col justify-center'><XCircleIcon
                               className="h-5 w-5 text-red-600"
                               aria-hidden="true"
                             /> <span className=' text-sm text-gray-800 px-1'>First installment</span>
                             </div>:<div className='flex items-center flex-col justify-center'>
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-700"
                                aria-hidden="true"
                              /> <span className=' text-sm text-gray-800 px-1'>First installment </span>
                             </div>
                            }  {personDetail?.registration_request?.second_installment_payment_reference==null||personDetail?.registration_request?.second_installment_payment_reference==undefined||personDetail?.registration_request?.second_installment_payment_reference==""?
                              <div className='flex items-center flex-col justify-center'><XCircleIcon
                                className="h-5 w-5 text-red-600"
                                aria-hidden="true"
                              /><span className=' text-sm text-gray-800 px-1'>Second installment </span></div>:<div className='flex items-center flex-col justify-center'>
                              <CheckCircleIcon
                                 className="h-5 w-5 text-green-700"
                                 aria-hidden="true"
                               /><span className=' text-sm text-gray-800 px-1'>Second installment</span></div>}
                                {personDetail?.registration_request?.full_payment_reference==null||personDetail?.registration_request?.full_payment_reference==undefined||personDetail?.registration_request?.full_payment_reference==""?
                           <div className='flex flex-col items-center justify-center'> <XCircleIcon
                           className="h-5 w-5 text-red-600"
                           aria-hidden="true"
                         /><span className=' text-sm text-gray-800 px-1'>Full payment </span></div>  :<div className='flex flex-col items-center justify-center'>
                             <CheckCircleIcon
                                className="h-5 w-5 text-green-700"
                                aria-hidden="true"
                              /><span className=' text-sm text-gray-800 px-1'>Full payment </span></div>}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Answer 

                              </dt>
                              <dd className="mt-1 text-sm  sm:col-span-2 sm:mt-0 sm:ml-6">
                             {personDetail?.registration_request?.answer} 
                              </dd>
                            </div>
                            {/* <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              {personDetail?.registration_request?.payment_package==="Accommodation"?"Accommodation Price":personDetail?.registration_request?.payment_package==="Non Accommodation"?"Non Accommodation Price":"Full Experience Price"}

                              </dt>
                              <dd className="mt-1 text-sm  sm:col-span-2 sm:mt-0 sm:ml-6">
                           {personDetail?.registration_request?.payment_package==="Accommodation"?personDetail?.price_breakdown?.breakdown[0]?.accommodation_price:personDetail?.registration_request?.payment_package==="Non Accommodation"?personDetail?.price_breakdown?.breakdown[0]?.non_accommodation_price:personDetail?.price_breakdown?.breakdown[0]?.full_experience_price}
                              </dd>
                            </div> */}
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment Package

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                              {personDetail?.registration_request?.payment_package}
                              </dd>
                            </div>
                             <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.payment_status}
                              </dd>
                            </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Plan Name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":personDetail?.registration_request?.applied_pricing_plan?.plan_name}
                              </dd>
                            </div>   <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Accommodation Pricing
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":personDetail?.registration_request?.applied_pricing_plan?.accommodation_pricing}
                              </dd>
                            </div> 
                             <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                             Non Accommodation Pricing
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":personDetail?.registration_request?.applied_pricing_plan?.non_accommodation_pricing}
                              </dd>
                            </div>   <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                             Full Experience Pricing
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":personDetail?.registration_request?.applied_pricing_plan?.full_experience_pricing}
                              </dd>
                            </div> 
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Processing Fee
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":personDetail?.registration_request?.applied_pricing_plan?.processing_fee}
                              </dd>
                            </div>
                             <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Valid From
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":moment(personDetail?.registration_request?.applied_pricing_plan?.valid_from).format('MM-DD-YYYY')}
                              </dd>
                            </div>  <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Valid Till
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.registration_request?.applied_pricing_plan==null?"N/A":moment(personDetail?.registration_request?.applied_pricing_plan?.valid_till).format('MM-DD-YYYY')}
                              </dd>
                            </div> 

                           <form onSubmit={formik.handleSubmit}  className="sm:divide-y sm:divide-gray-200">
                            <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Registration Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                <label htmlFor="registration_status" className="sr-only"></label>
                                <select
                                  id="registration_status"
                                  name="registration_status"
                                  className=" block w-60 rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  initialValues={personDetail?.registration_request?.registration_status}
                                  onChange={formik.handleChange}
                                  value={formik.values.registration_status}
                                  onMouseOut={()=> RejectedStatusReview(formik.values.registration_status) }
                                >
                                 
                                  <option value="Approved">Approved</option>
                                  <option value="In Review">In Review</option>
                                  <option value="Rejected">Rejected</option>
                                  <option value="Initial Reject">Initial Reject</option>
                                  <option value="Appealed">Appealed</option>
                                </select>
                              </dd>
                            </div> 
                         {onRejectedStatusReview?(<div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Rejected Comments
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                <div className="sm:col-span-2">
                                  <textarea
                                    id="rejection_comment"
                                    name="rejection_comment"
                                    rows={3}
                                    className="block w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.rejection_comment}
                                  />
                                </div>
                              </dd>
                            </div>):(null)}
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                              <button
                              
                              type="submit"
                            
                                className="mr-3 flex-row items-center  rounded inline-flex justify-center  border border-transparent  bg-blue-500 hover:bg-blue-700 py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Update Request
                              </button>
                            </div>
                            </form>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Popup for Picture */}
      <Transition.Root show={popUPImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopUPImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex justify-center items-center bg-orange-200">
                    <ReactPlayer url={currPersonLink} playing controls={true}/>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                     
                    <button
                      type="button"
                      className="mt-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopUPImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    
   </>
  )
}