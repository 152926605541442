import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Toaster, toast } from "sonner";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
var userEmail;
var Role;
var JSToken;
var accessToken;
if (localStorage.user) {
  Role = localStorage.role;
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};
export const TotalCountRep = () => {
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [totalCountRep, setTotalCountRep] = useState(null);
  useEffect(() => {
    setLoadingSkeleton(true);
    const fetchAmbData = async () => {
      try {
        await axios
          .get(`${window.$domain}/api/method/cr-stats`, config)
          .then((response) => {
            setTotalCountRep(response.data.message);
            console.log("totlat ", response.data.message);
            setLoadingSkeleton(false);
          });
      } catch (e) {
        console.log(e);
        setLoadingSkeleton(false);
        toast.error("No Data Found");
      }
    };
    fetchAmbData();
  }, []);
  return (
    <div>
      <Toaster position="bottom-left" richColors />
      {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3> */}
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow md:grid-cols-6 md:divide-x md:divide-y-1">
        <div className="px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items-center justify-center">
            <div className="flex items-center justify-center  text-lg font-semibold text-indigo-600">
              {totalCountRep?.total_reps===null||totalCountRep?.total_reps===undefined||totalCountRep?.total_reps===""? <Skeleton width={50} borderRadius={40} height={30} />:<span className="inline-flex rounded-full bg-blue-100 px-2 py-2 text-lg font-semibold leading-5 text-blue-600">{totalCountRep?.total_reps}</span>}
            </div>
          </dd>
          <dt className="text-base flex items-center justify-center font-normal text-gray-900">
            Total Reps
          </dt>
        </div>
        <div className="px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items-center justify-center">
            <div className="flex items-center justify-center  text-lg font-semibold text-indigo-600">
              {totalCountRep?.on_probation===null||totalCountRep?.on_probation===undefined||totalCountRep?.on_probation===""? <Skeleton width={50} borderRadius={40} height={30} />:<span className="inline-flex rounded-full bg-gray-100 px-2 py-2 text-lg font-semibold leading-5 text-gray-900">{totalCountRep?.on_probation}</span>}
            </div>
          </dd>
          <dt className="text-base flex items-center justify-center font-normal text-gray-900">
            On Probation
          </dt>
        </div>
        <div className="px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items-center justify-center">
            <div className="flex items-center justify-center  text-lg font-semibold text-indigo-600">
              {totalCountRep?.in_review===null||totalCountRep?.in_review===undefined||totalCountRep?.in_review===""? <Skeleton width={50} borderRadius={40} height={30} />:<span className="inline-flex rounded-full bg-orange-100 px-2 py-2 text-lg font-semibold leading-5 text-orange-600">{totalCountRep?.in_review}</span>}
            </div>
          </dd>
          <dt className="text-base flex items-center justify-center font-normal text-gray-900">
            In Review
          </dt>
        </div>

        <div className="px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items-center justify-center">
            <div className="flex items-center justify-center  text-lg font-semibold text-indigo-600">
              {totalCountRep?.rejected_profiles===null||totalCountRep?.rejected_profiles===undefined||totalCountRep?.rejected_profiles===""? <Skeleton width={50} borderRadius={40} height={30} />:<span className="inline-flex rounded-full bg-red-100 px-2 py-2 text-lg font-semibold leading-5 text-red-800">{totalCountRep?.rejected_profiles}</span>}
            </div>
          </dd>
          <dt className="text-base flex items-center justify-center font-normal text-gray-900">
            Rejected Profiles
          </dt>
        </div>
        <div className="px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items-center justify-center">
            <div className="flex items-center justify-center  text-lg font-semibold text-indigo-600">
              { totalCountRep?.approved_profiles===null||totalCountRep?.approved_profiles===undefined||totalCountRep?.approved_profiles===""? <Skeleton width={50} borderRadius={40} height={30} />:<span className="inline-flex rounded-full bg-green-100 px-2 py-2 text-lg font-semibold leading-5 text-green-800">{totalCountRep?.approved_profiles}</span>}
            </div>
          </dd>
          <dt className="text-base flex items-center justify-center font-normal text-gray-900">
            Approved Profiles
          </dt>
        </div>
        <div className="px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items-center justify-center">
            <div className="flex items-center justify-center  text-lg font-semibold text-indigo-600">
              {totalCountRep?.need_review===null||totalCountRep?.need_review===undefined||totalCountRep?.need_review===""? <Skeleton width={50} borderRadius={40} height={30} />:<span className="inline-flex rounded-full bg-orange-100 px-2 py-2 text-lg font-semibold leading-5 text-orange-700">{totalCountRep?.need_review}</span>}
            </div>
          </dd>
          <dt className="text-base flex items-center justify-center font-normal text-gray-900">
            Need Review
          </dt>
        </div>
      </dl>
      
    </div>
  );
};
