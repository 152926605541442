import { Fragment, useState, useEffect, useRef } from "react";
import { Toaster, toast } from "sonner";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Disclosure } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon, MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import PrivateImage from "../PrivateImage/PrivateImage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    department: "Optimization",
    email: "lindsay.walton@example.com",
    role: "Member",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More people...
];
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};
const ConferenceFeedBack = () => {
  
  const [open, setOpen] = useState(false);

  const { eventID } = useParams();
  const [conferenceFeedBack, setConferenceFeedBack] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [detailFeedBack, setDetailFeedBack] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [totalRoomCount, setTotalRoomCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    setLoadingSkeleton(true);
    const fetchFeedback = async () => {
      try {
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/get-feedback?event=${eventID}`,config
          )
          .then((response) => {
            // console.log(">>>>> ", response.data.data);
            setConferenceFeedBack(response.data.data);
            setTotalRoomCount(response.data.data.length )
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 40);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            setLoadingSkeleton(false);
            if (response.data.data.length == []) {
              setIsNotFound(true);
              toast.error("No Data Found");
              setLoadingSkeleton(true);
            }
          });
      } catch (e) {
        console.log(">>>>>> Error", e);
        if (e == "Error: Network Error") {
          setIsNotFound(true);
          toast.error("No Data Found");
      
        }
        if (e.code == "ERR_NETWORK" || e.code == "ERR_BAD_REQUEST") {
          setIsNotFound(true);
          toast.error("No Data Found");
          setLoadingSkeleton(true);
        }
      }
    };

    fetchFeedback();
  }, []);
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };
  const [fedback, setFedback] = useState([]);
  const DetailFeedback = (e) => {
    setDetailFeedBack(e);
    // console.log(e.countries_question);
    setFedback([
      {question:"Q#1 How was your experience with Best Diplomats virtual training sessions prior to the conference?", answer: `${e.virtual_training_question}`,},
      {
        question:
          "Q#2 Did you face any difficulties in the visa application process? If yes, please explain",
        answer: `${e.visa_experience_question}`,
      },
      {
        question:
          " Q#3 Please share your experience with the airport shuttle service?",
        answer: `${e.shuttle_service_question}`,
      },
      {
        question:
          "Q#4 How was your overall experience with the Best Diplomats conference?",
        answer: `${e.overall_experience_question}`,
      },
      {
        question:
          "Q#5 Please suggest two or three areas where you think Best Diplomats could improve?",
        answer: `${e.improvement_question}`,
      },
      {
        question:
          "Q#6 If you could change anything about the conference, what would it be?",
        answer: `${e.change_question}`,
      },
      {
        question:
          "Q#7 Did you face any challenges with your accommodation or room sharing arrangements?",
        answer: `${e.rooming_question}`,
      },
      {
        question:
          "Q#8 Please share three great memories from your participation in the conference?",
        answer: `${e.memories_question}`,
      },
      {
        question: "Q#9 How was the food at the conference?",
        answer: `${e.food_question}`,
      },
      {
        question:
          "Q#10 Please share your experience of the socials (city tour/Cruise Dinner) where the conference was held?",
        answer: `${e.tour_question}`,
      },
      {
        question:
          "Q#11 Can you write a few words about the diplomatic experts who participated in the conference?",
        answer: `${e.experts_question}`,
      },
      {
        question:
          "Q#12 Which Best Diplomats official did you admire the most, and why?",
        answer: `${e.admire_question}`,
      },
      {
        question:
          "Q#13 Would you like to attend future conferences organised by Best Diplomats?",
        answer: `${e.attend_future}`,
      },
      {
        question: "Q#14 What was your biggest takeaway from this conference?",
        answer: `${e.takeaway_question}`,
      },
      {
        question:
          "Q#15 How satisfied are you with the networking opportunities provided at the conference?",
        answer: `${e.networking_question}`,
      },
      {
        question:
          "Q#16 Did this conference improve your skills or overall understanding of the topic?",
        answer: `${e.topic_question}`,
      },
      {
        question:
          "Q#17 Which topics would you like to see covered in future conferences?",
        answer: `${e.future_topics_question}`,
      },
      {
        question:
          "Q#18 Would you recommend attending this event to a friend or colleague?",
        answer: `${e.recommend_question}`,
      },
      {
        question:
          "Q#19 Which countries would you like us to consider for organizing future conferences?",
        answer: `${e.countries_question}`,
      },
    ]);
    setOpen(true);
  };

  return (
    <>
      <div className="mt-4 flex flex-col max-h-screen">
        <div ref={tableRef}  className="-my-2 -mx-4 overflow-x-auto sm:-mx-4 lg:-mx-4">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
           
              {isNotFound ? (
                <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
</svg> <span className=" text-lg text-gray-400">No Feedback Found</span> 
                </div>
              ) : ( <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className=" bg-stone-100">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name & Email
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Recommend
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Attend Future
                      </th>
                      {/* <th
                      scope="col"
                      className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Bag
                    </th> */}

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  {loadingSkeleton ? (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      <tr>
                        <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-14 w-14 flex-shrink-0">
                              <Skeleton
                                borderRadius={40}
                                width={50}
                                height={50}
                              />
                            </div>
                            <div className="ml-2">
                              <div className="font-medium cursor-pointer text-gray-900">
                                <Skeleton width={90} height={10} />
                              </div>
                              <div className="text-gray-500">
                                <Skeleton width={80} height={10} />
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Skeleton borderRadius={40} width={60} height={20} />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Skeleton borderRadius={40} width={60} height={20} />
                        </td>

                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          <Skeleton borderRadius={40} width={30} height={30} />
                        </div>
                      </td> */}
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {conferenceFeedBack?.map((person) => (
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-4">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0"    onClick={() => DetailFeedback(person)}>
                              <PrivateImage imageUrl={  person.user?.user_image} className={"h-10 w-10 cursor-pointer rounded-full"}/> 
                              
                              </div>
                              <div className="ml-3">
                                <div
                                  className="font-medium cursor-pointer text-gray-900"
                                  onClick={() => DetailFeedback(person)}
                                >
                                  {person.user.first_name}{" "}
                                  {person.user.last_name}
                                </div>
                                <div
                                  className="text-gray-500 cursor-pointer"
                                  onClick={() => DetailFeedback(person)}
                                >
                                  {person.user.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.recommend_question == "Yes" ? (
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {person.recommend_question}
                              </span>
                            ) : person.recommend_question == "No" ? (
                              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                {person.recommend_question}
                              </span>
                            ) : (
                              <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                {person.recommend_question}
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.attend_future == "Yes" ? (
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {person.attend_future}
                              </span>
                            ) : person.attend_future == "No" ? (
                              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                {person.attend_future}
                              </span>
                            ) : (
                              <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                {person.attend_future}
                              </span>
                            )}
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.role}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            href="#"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit<span className="sr-only">, {person.name}</span>
                          </a>
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                </div>   )}
          
          </div>
        </div>
      </div>
      {isNotFound ? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 40 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (totalRoomCount % 40)}</span> of{" "}
                  <span className="font-medium">{totalRoomCount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Profile
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 bg-indigo-700 sm:h-20 lg:h-28" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                <PrivateImage imageUrl={detailFeedBack?.user?.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                  
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {detailFeedBack?.user?.first_name}{" "}
                                    {detailFeedBack?.user?.last_name}
                                  </h3>
                                </div>
                                <p className="text-sm text-gray-500">
                                  {detailFeedBack?.user?.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="flex flex-col sm:px-6 gap-2 mx-auto max-w-4xl divide-y divide-gray-900/10 sm:py-5">
                            {fedback.map((faq) => (
                              <Disclosure
                                as="div"
                                key={faq.question}
                                className="pt-3"
                              >
                                {({ open }) => (
                                  <>
                                    <dt>
                                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-sm font-medium text-gray-900 ml-3">
                                          {faq.question}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center">
                                          {open ? (
                                            <MinusSmallIcon
                                              className="h-6 w-6"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <PlusSmallIcon
                                              className="h-6 w-6"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </span>
                                      </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel
                                      as="dd"
                                      className="mt-2 pr-12"
                                    >
                                      <dd className="text-sm text-gray-700 ml-3">
                                        <p>
                                          <span className="pr-1 text-sm font-medium text-gray-900 ">
                                            Answer
                                          </span>
                                          {faq.answer}
                                        </p>
                                      </dd>
                                      {/* <p className="text-base leading-7 text-gray-600"></p> */}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            ))}
                            
                          </dl>


                         
                        </div>

                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ConferenceFeedBack;
